import { createPickup } from "@/api/pickups/createPickup";
import { storePickupData } from "@/api/pickups/storePickup";
import CreatePickup from "@/app/shipment/Pickups/CreatePickup";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { PickupData } from "@shared/PickupTypes";
import { Customer, PickupDetails, Shipment } from "@shared/ShipmentTypes";
import { useEffect, useState } from "react";
import { get, useFieldArray, useForm, useFormContext } from "react-hook-form";
import Form from "@/components/composite/Form";
import { z } from "zod";
import { createPickupSchema } from "@/schema/CreatePickupSchema";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/core/UserStore";
import { db } from "@/core/firebase";
import { getDocs, query, collection, where, setDoc, doc, updateDoc, deleteField } from "firebase/firestore";
import { Skeleton } from "@/components/ui/skeleton";
import _ from "lodash";
import AddressForm from "@/components/composite/AddressForm";
import FormTitle from "@/components/composite/Headers/FormTitle";
import CarrierLogo from "@/components/composite/CarrierLogo";
import Select from "@/components/composite/Inputs/Select";
import { getShipmentByID } from "@/api/shipments/getShipment";
import { Select as SelectField, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const CarrierList = [
    {
        name: "UPS",
        label: "UPS"
    },
    {
        name: "FedEx",
        label: "FedEx"
    },
    {
        name: "Purolator",
        label: "Purolator"
    }
];

export const carrierList = [
    { name: "GLS", label: "GLS" },
    { name: "UPS", label: "UPS" },
    { name: "FedEx", label: "FedEx" },
    { name: "Purolator", label: "Purolator" },
];

interface CreateDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleCreatePickup: Function;
}

export const CreatePickupDialog = ({ open, setOpen, handleCreatePickup }: CreateDialogProps) => {
    const { toast } = useToast();
    const [loading, setLoading] = useState(false);
    const user = useUserStore((state) => state.user);
    const addressBook = useAddressBookStore((state) => state.addressBook);
    const [defaultAddress, setDefaultAddress] = useState<Customer | null>(null)

    const roundToNextQuarter = (time) => {
        const remainder = 15 - (time.minute() % 15);
        return time.add(remainder, "minute").second(0).millisecond(0);
    };

    const defaultReadyTime = roundToNextQuarter(dayjs().add(15, "minute"));
    const defaultCloseTime = defaultReadyTime.add(3, "hour");
    const defaultReadyTimeDateObj = defaultReadyTime.toDate()

    const methods = useForm<z.infer<typeof createPickupSchema>>({
        resolver: zodResolver(createPickupSchema),
        mode: "onChange",
        defaultValues: {
            carrier: "",
            phoneNumber: "",
            companyName: "",
            attentionName: "",
            email: "",
            address: {
                city: "",
                countryCode: "",
                postalCode: "",
                stateCode: "",
                street: "",
                line2: "",
                // residential: false // dont add this, equality check in use existing pickups will fail since the key wont be there if not checked
            },
            pickupLocation: "frontDoor",
            readyDate: defaultReadyTimeDateObj,
            readyTime: defaultReadyTimeDateObj,
            closeTime: defaultCloseTime.toDate(),
            specialNote: ""
        }
        
    });

    useEffect(() => {
        const fetchAddresses = async () => {
            if (!user || !user.email) return;
            
            const addresses = await getAddresses(user.email);

            if (addresses) {
                for (const address of addresses) {
                    if (address.defaultPickup) {
                        methods.setValue("attentionName", address.attentionName || "");
                        methods.setValue("companyName", address.companyName || "");
                        methods.setValue("email", address.email || "");
                        methods.setValue("phoneNumber", address.phoneNumber || "");
    
                        methods.setValue("address", {
                            ...methods.getValues("address"),
                            street: address.address.street,
                            line2: address.address.line2,
                            city: address.address.city,
                            postalCode: address.address.postalCode,
                            stateCode: address.address.stateCode,
                            countryCode: address.address.countryCode,
                        });
                    }
                }
            }
        }
        fetchAddresses()
    }, [user])

    const handleDefault = async (value: boolean) => {
        if (value && addressBook && user && user.email) {
            const defaultValues = methods.getValues();
            const address: Customer = {
                attentionName: defaultValues.attentionName,
                companyName: defaultValues.companyName,
                email: defaultValues.email,
                phoneNumber: defaultValues.phoneNumber,
                address: defaultValues.address,
                notifications: defaultValues.notifications,
            }

            if (defaultValues.defaultShipper) {
                address.defaultShipper = true
            }

            if (defaultValues.defaultReceiver) {
                address.defaultReceiver = true
            }
            
            setDefaultAddress(address)
        } else {
            setDefaultAddress(null)
        }
    }

    const handleSaveDefault = async () => {
        if (user && user.email && defaultAddress && addressBook) {
            
            for (const _address of addressBook) {
                if (_address.defaultPickup) {
                    const { defaultPickup, ...rest } = _address
                    await editAddress(_address, rest, user.email)
                }
            }

            await editAddress(defaultAddress, { ...defaultAddress, defaultPickup: true }, user.email)
        }
    }

    const handleSubmit = async (data: z.infer<typeof createPickupSchema>) => {
        setLoading(true);
        const readyDate = dayjs(data.readyDate)
        const readyTime = dayjs(data.readyTime)
        const closeTime = dayjs(data.closeTime)
        const readyDateFormat = readyDate.format("YYYY-MM-DD")

        const udpatedReadyTime = readyDate
        .hour(readyTime.hour())
        .minute(readyTime.minute())
        .second(readyTime.second());

        const udpatedCloseTime = readyDate
        .hour(closeTime.hour())
        .minute(closeTime.minute())
        .second(closeTime.second());
        
        // TODO: use proper create pickup endpoint
        let shipment: Shipment = {
            shipper: {
                companyName: data.companyName || data.attentionName,
                attentionName: data.attentionName,
                phoneNumber: data.phoneNumber,
                email: data.email || "developer@snapship.co",
                address: data.address,
            },
            // not actually needed, just to satisfy Shipment type
            receiver: [
                {
                    companyName: data.companyName || data.attentionName,
                    attentionName: data.attentionName,
                    phoneNumber: data.phoneNumber,
                    email: data.email || "developer@snapship.co",
                    address: data.address,
                }
            ],
            pickupDetails: {
                pickupType: "pickup",
                readyTimeStamp: {
                    date: readyDateFormat,
                    time: udpatedReadyTime.format("HH:mm") + ":00",
                    isoTimeStamp: udpatedReadyTime.format()
                },
                closeTimeStamp: {
                    date: readyDateFormat,
                    time: udpatedCloseTime.format("HH:mm") + ":00",
                    isoTimeStamp: udpatedCloseTime.format()
                },
                pickupLocation: data.pickupLocation,
                specialNote: data.specialNote
            },
            // not actually needed, just to satisfy Shipment type
            shipmentDetails: {
                deliveryFormat: "letter",
                shipmentType: "Letter",
                units: "imperial",
                packages: [{ weight: 1, length: 0, width: 0, height: 0 }],
                description: "automatic pickup setup",
                printerType: "regular"
            },
            serviceDetails: {
                carrier: data.carrier as "UPS" | "FedEx" | "Purolator" | "GLS",
                serviceCode: "01",
                serviceName: "Fake Service Name"
            },
            uid: user!.email
        };
        try {
            const response = await createPickup(shipment)
            const pickupData: PickupData = {
                data: response,
                carrier: shipment.serviceDetails!.carrier,
                shipper: shipment.shipper,
                shipmentID: [],
                uid: user!.email as string,
                pickupDetails: shipment.pickupDetails
            };

            const id = await generatePickupID();

            pickupData.id = id

            await setDoc(doc(db, "pickups", id), pickupData);

            setOpen(false);
            toast({ title: "Pickup scheduled", description: `Your pickup is scheduled!`, variant: "success" });

            handleCreatePickup(pickupData)
        } catch(e) {
            const description = handlePickupErrorToastDescription(e, shipment.serviceDetails?.carrier);

            toast({ title: "Pickup not scheduled", description, variant: "destructive" });
        } finally {
            setLoading(false);
        }

        handleSaveDefault()
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="max-h-[90vh]  max-w-3xl w-full overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>Schedule a Pickup</DialogTitle>
                        <DialogDescription>
                            <p>To edit the pickup, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>

                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-6">
                        
                        <Card>
                            <CardHeader className="p-4">
                                <FormTitle>Select Carrier</FormTitle>
                            </CardHeader>
                            <CardContent className="grid gap-4">
                                <CarrierSelect />
                            </CardContent>
                        </Card>
                        {/* <Address /> */}
                        {/* <hr />
                        <AvailableShipment carrier={carrier} />
                        <hr /> */}
                        <Card>
                            <CardHeader className="p-4">
                                <FormTitle>Select Pickup Address</FormTitle>
                            </CardHeader>
                            <CardContent className="grid gap-4">
                                <AddressForm />
                                <Checkbox name="defaultPickup" label="Set this address as the default pickup address" onChange={handleDefault} />
                            </CardContent>
                        </Card>
                        <CreatePickupForm />
                        <LoadingButton isLoading={loading} type="submit">
                            Schedule Pickup
                        </LoadingButton>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

interface EditDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditPickup: Function;
    pickup: PickupData
}
export const EditPickupDialog = ({ open, setOpen, handleEditPickup, pickup }: EditDialogProps) => {
    const { toast } = useToast();
    const [loading, setLoading] = useState(false);
    const user = useUserStore((state) => state.user);
    const addressBook = useAddressBookStore((state) => state.addressBook);
    const [defaultAddress, setDefaultAddress] = useState<Customer | null>(null)

    const methods = useForm<z.infer<typeof createPickupSchema>>({
        resolver: zodResolver(createPickupSchema),
        mode: "onChange",
        defaultValues: {
            id: pickup.id,
            data: pickup.data,
            carrier: pickup.carrier,
            phoneNumber: pickup.shipper!.phoneNumber,
            companyName: pickup.shipper!.companyName,
            attentionName: pickup.shipper!.attentionName,
            email: pickup.shipper!.email,
            address: {
                city: pickup.shipper!.address.city,
                countryCode: pickup.shipper!.address.countryCode,
                postalCode: pickup.shipper!.address.postalCode,
                stateCode: pickup.shipper!.address.stateCode,
                street: pickup.shipper!.address.street,
                line2: pickup.shipper!.address.line2,
                residential: pickup.shipper!.address.residential
            },
            pickupLocation: pickup.pickupDetails.pickupLocation,
            readyDate: new Date(pickup.pickupDetails.readyTimeStamp!.isoTimeStamp!),
            readyTime: new Date(pickup.pickupDetails.readyTimeStamp!.isoTimeStamp!),
            closeTime: new Date(pickup.pickupDetails.closeTimeStamp!.isoTimeStamp!),
            specialNote: pickup.pickupDetails.specialNote
        }
    });

    useEffect(() => {
        methods.setValue("id", pickup.id)
        methods.setValue("data", pickup.data)
        methods.setValue("carrier", pickup.carrier)
        methods.setValue("phoneNumber", pickup.shipper!.phoneNumber)
        methods.setValue("companyName", pickup.shipper!.companyName)
        methods.setValue("attentionName", pickup.shipper!.attentionName)
        methods.setValue("email", pickup.shipper!.email as string)
        methods.setValue("address", {
            city: pickup.shipper!.address.city,
            countryCode: pickup.shipper!.address.countryCode,
            postalCode: pickup.shipper!.address.postalCode,
            stateCode: pickup.shipper!.address.stateCode,
            street: pickup.shipper!.address.street,
            line2: pickup.shipper!.address.line2,
            residential: pickup.shipper!.address.residential
        })
        methods.setValue("pickupLocation", pickup.pickupDetails.pickupLocation! || "frontDoor")
        methods.setValue("readyDate", new Date(pickup.pickupDetails.readyTimeStamp!.isoTimeStamp!))
        methods.setValue("readyTime", new Date(pickup.pickupDetails.readyTimeStamp!.isoTimeStamp!))
        methods.setValue("closeTime", new Date(pickup.pickupDetails.closeTimeStamp!.isoTimeStamp!))
        methods.setValue("specialNote", pickup.pickupDetails.specialNote)
    }, [pickup])

    useEffect(() => {
        const fetchAddresses = async () => {
            if (!user || !user.email) return;
            
            const addresses = await getAddresses(user.email);

            if (addresses) {
                useAddressBookStore.getState().addAddressBook(addresses);

                for (const address of addresses) {
                    if (address.defaultPickup) {
                        methods.setValue("attentionName", address.attentionName || "");
                        methods.setValue("companyName", address.companyName || "");
                        methods.setValue("email", address.email || "");
                        methods.setValue("phoneNumber", address.phoneNumber || "");
    
                        methods.setValue("address", {
                            ...methods.getValues("address"),
                            street: address.address.street,
                            line2: address.address.line2,
                            city: address.address.city,
                            postalCode: address.address.postalCode,
                            stateCode: address.address.stateCode,
                            countryCode: address.address.countryCode,
                        });
                    }
                }
            }
        }
        fetchAddresses()
    }, [user])

    const handleDefault = async (value: boolean) => {
        if (value && addressBook && user && user.email) {
            const defaultValues = methods.getValues();
            const address: Customer = {
                attentionName: defaultValues.attentionName,
                companyName: defaultValues.companyName,
                email: defaultValues.email,
                phoneNumber: defaultValues.phoneNumber,
                address: defaultValues.address,
                notifications: defaultValues.notifications,
            }

            if (defaultValues.defaultShipper) {
                address.defaultShipper = true
            }

            if (defaultValues.defaultReceiver) {
                address.defaultReceiver = true
            }
            
            setDefaultAddress(address)
        } else {
            setDefaultAddress(null)
        }
    }

    const handleSaveDefault = async () => {
        if (user && user.email && defaultAddress && addressBook) {
            
            for (const _address of addressBook) {
                if (_address.defaultPickup) {
                    const { defaultPickup, ...rest } = _address
                    await editAddress(_address, rest, user.email)
                }
            }

            await editAddress(defaultAddress, { ...defaultAddress, defaultPickup: true }, user.email)
        }
    }

    const handleSubmit = async (data: z.infer<typeof createPickupSchema>) => {
        setLoading(true);

        // TODO: move all this logic to the backend, should be a single call

        const readyDate = dayjs(data.readyDate)
        const readyTime = dayjs(data.readyTime)
        const closeTime = dayjs(data.closeTime)
        const readyDateFormat = readyDate.format("YYYY-MM-DD")

        const udpatedReadyTime = readyDate
        .hour(readyTime.hour())
        .minute(readyTime.minute())
        .second(readyTime.second());

        const udpatedCloseTime = readyDate
        .hour(closeTime.hour())
        .minute(closeTime.minute())
        .second(closeTime.second());

        const updatedPickup: PickupData = {
            id: data.id,
            data: data.data,
            carrier: data.carrier,
            shipmentID: [],
            uid: user!.email as string,
            shipper: {
                phoneNumber: data.phoneNumber,
                companyName: data.companyName,
                attentionName: data.attentionName,
                email: data.email,
                address: {
                    city: data.address.city,
                    countryCode: data.address.countryCode,
                    postalCode: data.address.postalCode,
                    stateCode: data.address.stateCode,
                    street: data.address.street,
                    line2: data.address.line2,
                    residential: data.address.residential
                },
            },
            pickupDetails: {
                pickupType: "pickup",
                readyTimeStamp: {
                    date: readyDateFormat,
                    time: udpatedReadyTime.format("HH:mm") + ":00",
                    isoTimeStamp: udpatedReadyTime.format()
                },
                closeTimeStamp: {
                    date: readyDateFormat,
                    time: udpatedCloseTime.format("HH:mm") + ":00",
                    isoTimeStamp: udpatedCloseTime.format()
                },
                pickupLocation: data.pickupLocation,
                specialNote: data.specialNote
            },
            status: "Scheduled"
        }

        let shipment: Shipment = {
            shipper: {
                companyName: data.companyName || data.attentionName,
                attentionName: data.attentionName,
                phoneNumber: data.phoneNumber,
                email: data.email,
                address: data.address,
            },
            // not actually needed, just to satisfy Shipment type
            receiver: [
                {
                    companyName: data.companyName || data.attentionName,
                    attentionName: data.attentionName,
                    phoneNumber: data.phoneNumber,
                    email: data.email,
                    address: data.address,
                }
            ],
            pickupDetails: updatedPickup.pickupDetails,
            // not actually needed, just to satisfy Shipment type
            shipmentDetails: {
                deliveryFormat: "letter",
                shipmentType: "Letter",
                units: "imperial",
                packages: [{ weight: 1, length: 0, width: 0, height: 0 }],
                description: "automatic pickup setup",
                printerType: "regular"
            },
            serviceDetails: {
                carrier: data.carrier as "UPS" | "FedEx" | "Purolator" | "GLS",
                serviceCode: "01",
                serviceName: "Fake Service Name"
            },
            uid: user!.email
        };

        const cancelStatus = await cancelPickup(updatedPickup);

        if (cancelStatus === "Pickup not cancelled") {
            toast({ title: "Pickup not Rescheduled", description: "Could not cancel pickup", variant: "destructive" });
            setOpen(false);
            setLoading(false);
            return;
        }

        try {
            updatedPickup.data = await createPickup(shipment);
        } catch (error) {
            console.error("Error creating pickup through API:", error);

            const description = handlePickupErrorToastDescription(error, data?.carrier);

            toast({ title: "Pickup not Rescheduled", description, variant: "destructive" });

            setOpen(false);
            setLoading(false);
            handleEditPickup(null)
            return;
        }

        try {
            const pickupRef = doc(db, "pickups", data.id as string);
            await updateDoc(pickupRef, updatedPickup);
        } catch (e) {
            // TODO: how to handle when this fails?

            console.error("Error updating firebase with new pickup info:", e);

            toast({ title: "Pickup not Rescheduled", description: "Service unavailable, please try again", variant: "destructive" });

            setOpen(false);
            setLoading(false);
            handleEditPickup(null)
            return;
        }

        toast({
            title: "Pickup Rescheduled",
            description: `Your pickup is now rescheduled for ${dayjs(data.readyTime).format("MMM D, YYYY")} from ${dayjs(data.readyTime).format("h:mmA")} to ${dayjs(data.closeTime).format("h:mmA")}`
        });
        setOpen(false);
        setLoading(false);

        handleEditPickup(updatedPickup)

        handleSaveDefault()
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="max-h-[90vh]  max-w-3xl w-full overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>Edit Pickup</DialogTitle>
                        <DialogTitle className="text-md text-gray-600">{pickup.id}</DialogTitle>
                        <DialogDescription>
                            <p>To edit the pickup, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>

                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-6">
                        
                        <Card>
                            <CardHeader className="p-4">
                                <FormTitle>Select Carrier</FormTitle>
                            </CardHeader>
                            <CardContent className="grid gap-4">
                                <CarrierSelect />
                            </CardContent>
                        </Card>
                        {/* <Address /> */}
                        {/* <hr />
                        <AvailableShipment carrier={carrier} />
                        <hr /> */}
                        <Card>
                            <CardHeader className="p-4">
                                <FormTitle>Select Pickup Address</FormTitle>
                            </CardHeader>
                            <CardContent className="grid gap-4">
                                <AddressForm />
                                <Checkbox name="defaultPickup" label="Set this address as the default pickup address" onChange={handleDefault} />
                            </CardContent>
                        </Card>
                        <CreatePickupForm />
                        <LoadingButton isLoading={loading} type="submit">
                            Schedule Pickup
                        </LoadingButton>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

const Address = () => {
    return (
        <Card className="w-full">
            <CardHeader className="p-4">
                <FormTitle>Shipper Address</FormTitle>
            </CardHeader>
            <CardContent className="w-full p-4">
                {/* <Select name={"ShipperAddressBook"} SelectItemList={SelectItemList} placeholder={"Select from your Address Book"} label={"Select Address"} />
                    <Separator text="or" /> */}
                <AddressForm />
            </CardContent>
        </Card>
    );
};

const fetchShipments = async (uid: string, carrier: string) => {
    try {
        let shipments: Shipment[] = [];

        const shipmentsQuery = await getDocs(
            query(
                collection(db, "shipments"),
                where("uid", "==", uid),
                where("pickupDetails.pickupType", "==", "pickup"),
                where("pickupDetails.id", "==", null),
                where("serviceDetails.carrier", "==", carrier)
            )
        );

        if (!shipmentsQuery.empty) {
            shipmentsQuery.docs.forEach((shipmentDoc) => {
                const data = shipmentDoc.data() as Shipment;
                shipments.push(data);
            });
        }

        if (shipments.length > 0) {
            return shipments;
        } else {
            return undefined;
        }
    } catch (e) {
        console.error("Error fetching available shipments:", e);
    }
};

interface AvailableShipment {
    carrier: string;
}

const AvailableShipment = ({ carrier }: AvailableShipment) => {
    const uid = useUserStore((state) => state.userData)?.email;

    const { replace } = useFieldArray({ name: "shipmentID" });

    const {
        formState: { errors }
    } = useFormContext();

    // const [availableShipment, setAvailableShipment] = useState<Shipment[] | undefined>([]);
    const [allShipments, setAllShipments] = useState<Shipment[] | undefined>([]);
    const [availableShipment, setAvailableShipment] = useState({});
    const [activeShipment, setActiveShipment] = useState<string[]>([]);

    useEffect(() => {
        uid &&
            fetchShipments(uid, carrier).then((response) => {
                setAllShipments(response);
                if (response) {
                    let sortedShipments: object = {};

                    response.forEach((shipment) => {
                        if (!(shipment.receiver[0].address.street in sortedShipments)) {
                            sortedShipments[shipment.receiver[0].address.street] = [];
                        }
                        (sortedShipments[shipment.receiver[0].address.street] as Shipment[]).push(shipment);
                    });

                    setAvailableShipment(sortedShipments);
                }
            });
        if (carrier) {
            setActiveShipment([]);
            replace([]);
        }
    }, [uid, carrier]);

    const errorMessage = get(errors, "shipmentID")?.message;

    return (
        <div>
            {allShipments ? (
                Object.entries(availableShipment).length > 0 ? (
                    <>
                        {Object.entries(availableShipment).map(([address, shipments]) => {
                            return (
                                <div className="grid gap-4">
                                    <h1 className="font-bold">Shipments going to {address}:</h1>
                                    <div className="flex gap-2 overflow-x-auto">
                                        {(shipments as Shipment[]).map((shipment) => {
                                            return <ShipmentCard shipment={shipment as Shipment} setActiveShipment={setActiveShipment} activeShipment={activeShipment} allAddresses={allShipments} />;
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {errorMessage !== undefined ? <p className="text-error-500 text-xs">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
                    </>
                ) : (
                    <Skeleton className="h-10 w-full" />
                )
            ) : (
                <div className="col-span-2 h-10 w-full items-center text-center">{"No shipments available."}</div>
            )}
        </div>
    );
};

interface ShipmentCard {
    shipment: Shipment;
    activeShipment: string[];
    setActiveShipment: React.Dispatch<React.SetStateAction<string[]>>;
    allAddresses?: Shipment[];
}

const ShipmentCard = ({ shipment, setActiveShipment, activeShipment, allAddresses }: ShipmentCard) => {
    const { setValue, reset, getValues, setError, clearErrors } = useFormContext();

    const FieldArray = useFieldArray({ name: "shipmentID" });

    const handleClick = () => {
        if (shipment.id) {
            clearErrors("shipmentID");
            if (activeShipment.includes(shipment.id)) {
                let list = activeShipment;
                const index = list.indexOf(shipment.id);
                list.splice(index, 1);
                setActiveShipment([...list]);
                FieldArray.remove(index);
            } else {
                // const currentShipment = allAddresses?.find((currentShipment) => {
                //     return activeShipment[0] === shipment.id
                // })

                const street = allAddresses?.find((value) => {
                    return value.id === activeShipment[0];
                })?.shipper.address.street;

                if (activeShipment.length === 0 || shipment.shipper.address.street === street) {
                    setActiveShipment([...activeShipment, shipment.id]);
                    FieldArray.append(shipment.id);
                } else {
                    setError("shipmentID", { message: "Address don't match" });
                }
            }
        }
        setValue("pickupType", "pickup");
    };
    return (
        <Card className="grid h-fit w-48 gap-2 p-4">
            <CardHeader className="p-0">
                <CardTitle className="grid items-center gap-3 text-sm">
                    <CarrierLogo carrier={shipment.serviceDetails?.carrier} className={"h-3.5 object-cover "} />
                    {shipment.id}
                    {shipment.receiver.map((receiver, index) => (
                        <span key={index}>{receiver.attentionName}</span>
                    ))}
                </CardTitle>
                {/* <CardDescription>
                    {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to{" "}
                    {dayjs(pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}
                </CardDescription> */}
            </CardHeader>
            <CardContent className="p-0">
                <Button type="button" variant={activeShipment?.includes(shipment.id as string) ? "default" : "secondary"} className="w-full" onClick={handleClick}>
                    {activeShipment?.includes(shipment.id as string) ? "Shipment selected" : "Use this shipment"}
                </Button>
            </CardContent>
        </Card>
    );
};

import Textbox from "@/components/composite/Inputs/Textbox";
import { Label } from "@/components/ui/label";
import { Input as InputField } from "@/components/ui/input";
import dayjs from "dayjs";
import { handlePickupErrorToastDescription } from "@/lib/errorHandling";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { generatePickupID } from "@/api/generateID";
import DatePicker from "@/components/composite/Inputs/DatePicker";
import { cancelPickup } from "@/api/pickups/updatePickups";
import { useAddressBookStore } from "@/core/AddressBookStore";
import { addAddress, editAddress, getAddresses, removeAddress } from "@/api/addresses";
import Checkbox from "@/components/composite/Inputs/Checkbox";

const PickupLocation = [
    { name: "frontDoor", label: "Front door" },
    { name: "backEntrance", label: "Back entrance" },
    { name: "mainLobby", label: "Main lobby" },
    { name: "loadingDock", label: "Loading dock" },
    { name: "receptionDesk", label: "Reception desk" },
    { name: "mailroom", label: "Mailroom" },
];

export default function CreatePickupForm() {
    const {
        register,
        formState: { errors },
        setValue,
        getValues } = useFormContext();

    const [date, setDate] = useState<Date>(getValues("readyDate"));
    const [readyTime, setReadyTime] = useState<Date>(getValues("readyTime"));
    const [closeTime, setCloseTime] = useState<Date>(getValues("closeTime"));
    const [pickupLocation, setPickupLocation] = useState<string | undefined>(getValues("pickupLocation"));

    useEffect(() => {

        if (date) {
            setValue("readyDate", date)
        }
        if (readyTime) {
            setValue("readyTime", readyTime);
        }

        if (closeTime) {
            setValue("closeTime", closeTime);
        }

        if (pickupLocation) {
            setValue("pickupLocation", pickupLocation)
        }
    }, [date, readyTime, closeTime, pickupLocation, setValue]);

    const errorMessage = get(errors, "readyTime")?.message;

    return (
        <>
            <Card>
                <CardHeader>
                    <FormTitle>Schedule Pickup</FormTitle>
                </CardHeader>
                <CardContent className="grid grid-cols-2 gap-6 p-4">
                    {/* <TimePicker name="readyTime" label="Ready Time" date={readyTime} setDate={setReadyTime} />
                    <TimePicker name="closeTime" label="Close Time" date={closeTime} setDate={setCloseTime} /> */}
                   <DatePicker date={date} setDate={setDate} name="readyDate" label="Ready Date" />
                    <Select
                        label="Pickup Location"
                        SelectItemList={PickupLocation}
                        name="pickupLocation"
                        onChange={setPickupLocation}
                        defaultValue={pickupLocation}
                    />
                    <div className="grid gap-2">
                        <Label>{"Ready Time"}</Label>
                        <InputField
                            type={"time"}
                            value={readyTime.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}
                            onChange={e => {
                                const timeValue = e.target.value; // Get value from input (HH:mm)

                                // Split hours and minutes
                                const [hours, minutes] = timeValue.split(':').map(Number);

                                // Update readyTime with the new hours and minutes while keeping the date
                                const updatedDate = new Date(readyTime);
                                updatedDate.setHours(hours);
                                updatedDate.setMinutes(minutes);

                                // Set the updated Date object
                                setReadyTime(updatedDate);
                                
                                const updatedClose = new Date(readyTime);
                                updatedClose.setHours(hours + 3);
                                updatedClose.setMinutes(minutes);
                                setCloseTime(updatedClose)

                            }}
                            className={errorMessage !== undefined ? "border-error-500 focus-visible:ring-0" : "ring-brand-500"}
                        />
                        {get(errors, "readyTime")?.message !== undefined ? <p className="text-error-500 text-xs">{get(errors, "readyTime")?.message}</p> : <p className="block text-xs text-red-500"> </p>}
                    </div>
                    <div className="grid gap-2">
                        <Label>{"Close Time"}</Label>
                        <InputField
                            type={"time"}
                            value={closeTime.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}
                            onChange={e => {
                                const timeValue = e.target.value; // Get value from input (HH:mm)

                                // Split hours and minutes
                                const [hours, minutes] = timeValue.split(':').map(Number);

                                // Update closeTime with the new hours and minutes while keeping the date
                                const updatedDate = new Date(closeTime);
                                updatedDate.setHours(hours);
                                updatedDate.setMinutes(minutes);

                                // Set the updated Date object
                                setCloseTime(updatedDate);
                            }}
                            className={errorMessage !== undefined ? "border-error-500 focus-visible:ring-0" : "ring-brand-500"}
                        />
                        {get(errors, "closeTime")?.message !== undefined ? <p className="text-error-500 text-xs">{get(errors, "closeTime")?.message}</p> : <p className="block text-xs text-red-500"> </p>}
                    </div>
                    <Textbox name="specialNote" label="Instructions for the driver (Optional)" className="col-span-2" />
                </CardContent>
            </Card>
        </>

    );
}


function CarrierSelect() {
    const {
        formState: { errors },
        setValue,
        watch,
    } = useFormContext();

    const errorMessage = get(errors, "carrier")?.message;

    return (
        <div className="grid gap-2">
            {/* <Label htmlFor="carrier">Carrier</Label> */}
            <SelectField
                defaultValue={watch("carrier")}  // Use watch to track changes
                onValueChange={(value) => setValue("carrier", value, { shouldValidate: true })}
            >
                <SelectTrigger className={errorMessage ? "border-error-500 focus-visible:ring-0" : "ring-brand-500"} id="carrier">
                    <SelectValue placeholder="Select a carrier" />
                </SelectTrigger>
                <SelectContent>
                    {carrierList.map((item, index) => (
                        <SelectItem value={item.name} key={index}>
                            {item.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </SelectField>
            {errorMessage ? (
                <p className="text-xs text-red-500" role="error">
                    {errorMessage}
                </p>
            ) : (
                <p className="block text-xs text-red-500"> </p>
            )}
        </div>
    );
}