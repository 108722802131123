import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import { Link, useNavigate } from "react-router-dom";
import { CircleUser } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { useUserStore } from "@/core/UserStore";
import { useAddressBookStore } from "@/core/AddressBookStore";

export default function Header({ showAccountIcon = true }: { showAccountIcon?: boolean }) {
    return (
        <header className="relative flex w-full border-b px-6 py-4 align-middle">
            <div className="h-full w-fit">
                <Link to="/" className="appearance-none">
                    <img src={logo} className="h-10 object-cover" />
                </Link>
            </div>
            {showAccountIcon && <div className="ml-auto flex gap-4">
                {/* <Search className="h-4 w-4"></Search> */}
                {/* <Input type="search" placeholder="Search SnapShip" className="bg-background w-[384px] appearance-none shadow-none"></Input> */}
                <Account />
            </div>}
        </header>
    );
}

const Account = () => {
    const user = useUserStore((state) => state.user);
    const logout = useUserStore((state) => state.logout);

    const navigate = useNavigate();

    async function handleLogout() {
        try {
            logout();
            useAddressBookStore.getState().removeAddressBook();
            navigate("/login", { replace: true });
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Avatar>
                    <AvatarImage src={user?.photoURL || undefined} />
                    <AvatarFallback>
                        <Button variant="secondary" size="icon" className="rounded-full">
                            <CircleUser className="h-5 w-5" />
                        </Button>
                    </AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">{user?.displayName}</p>
                        <p className="text-muted-foreground text-xs leading-none">{user?.email}</p>
                    </div>
                </DropdownMenuLabel>
                {/* <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <Link to="settings">
                        <DropdownMenuItem>Settings</DropdownMenuItem>
                    </Link>
                    <Link to="">
                        <DropdownMenuItem>Support</DropdownMenuItem>
                    </Link>
                </DropdownMenuGroup> */}
                <DropdownMenuSeparator />

                <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
