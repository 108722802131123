import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Shipment } from "@shared/ShipmentTypes";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { pickupDetailsSchema } from "@/schema/ShipmentSchema";
import { useForm } from "react-hook-form";
import Form from "@/components/composite/Form";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { createPickup } from "@/api/pickups/createPickup";
import { storePickupData } from "@/api/pickups/storePickup";
import { addPickupToShipment } from "@/api/pickups/updatePickups";
import CreatePickup from "@/app/shipment/Pickups/CreatePickup";
import UseExistingPickup from "@/app/shipment/Pickups/UseExistingPickup";
import { DialogHeader, DialogFooter } from "@/components/ui/dialog";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { PickupData } from "@shared/PickupTypes";
import { z } from "zod";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { handlePickupErrorToastDescription } from "@/lib/errorHandling";
import { useNavigate } from "react-router-dom";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";

export default function SchedulePickup({ shipment, setShipment, setPickup }: { shipment: Shipment; setShipment: Function; setPickup: Function }) {
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [openExistingDialog, setOpenExistingDialog] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <Card className="h-fit border-2">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold">Schedule Pickup</CardTitle>
                    <CardDescription className="my-2">This shipment doesn't have a pickup assigned to it yet.</CardDescription>
                </CardHeader>
                <CardContent className=" grid grid-cols-2 gap-4">
                    <Button variant={"secondary"} onClick={() => setOpenScheduleDialog(!openScheduleDialog)}>
                        Schedule Pickup now
                    </Button>
                    <Button variant={"secondary"} onClick={() => setOpenExistingDialog(!openExistingDialog)}>
                        Use Existing Pickup
                    </Button>
                </CardContent>
                <CardFooter>
                    <div className="col-span-2 text-xs text-gray-500">
                        Having trouble scheduling a pickup?{" "}
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <span
                                        className="cursor-pointer text-blue-500 underline">
                                        Let us know.
                                    </span>
                                </TooltipTrigger>
                                <TooltipContent className="max-w-xs font-normal">
                                    <p className="">
                                        Please note that if you do not see a pick-up confirmation number, your pick-up is not confirmed. Try scheduling the pick-up again or email{" "}
                                        <a className="cursor-pointer text-blue-500 underline" href="mailto:info@snapship.co">
                                            info@snapship.co
                                        </a>{" "}
                                        for further support.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                </CardFooter>
            </Card>
            <SchedulePickupDialog shipment={shipment} open={openScheduleDialog} setOpen={setOpenScheduleDialog} setShipment={setShipment} setPickup={setPickup} />
            <ExistingPickupDialog shipment={shipment} open={openExistingDialog} setOpen={setOpenExistingDialog} setShipment={setShipment} setPickup={setPickup} />
        </>
    );
}

interface DialogProps {
    shipment: Shipment;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShipment: Function;
    setPickup: Function;
}

export const SchedulePickupDialog = ({ shipment, open, setOpen, setShipment, setPickup }: DialogProps) => {
    const { toast } = useToast();

    const [loading, setLoading] = useState(false);
    const methods = useForm<z.infer<typeof pickupDetailsSchema>>({
        resolver: zodResolver(pickupDetailsSchema),
        mode: "onChange",
        defaultValues: {
            pickupType: "pickup",
            scheduleType: "scheduleNow",
            carrier: shipment.serviceDetails?.carrier,
            pickupLocation: shipment.pickupDetails.pickupLocation,
            readyTimeStamp: {
                date: shipment.pickupDetails.readyTimeStamp?.date,
                time: shipment.pickupDetails.readyTimeStamp?.time,
                isoTimeStamp: shipment.pickupDetails.readyTimeStamp?.isoTimeStamp
            },
            closeTimeStamp: {
                date: shipment.pickupDetails.closeTimeStamp?.date,
                time: shipment.pickupDetails.closeTimeStamp?.time,
                isoTimeStamp: shipment.pickupDetails.closeTimeStamp?.isoTimeStamp
            }
        }
    });

    const handleSubmit = (data: z.infer<typeof pickupDetailsSchema>) => {
        setLoading(true);
        if (shipment) {
            console.log(data);
            shipment.pickupDetails = data;
            createPickup(shipment)
                .then(async (response) => {
                    console.log(response);
                    const pickupData: PickupData = {
                        data: response,
                        carrier: shipment.serviceDetails?.carrier ?? "",
                        shipper: shipment.shipper ?? "",
                        shipmentID: [shipment.id ?? ""],
                        uid: shipment.uid ?? "",
                        pickupDetails: data
                    };

                    console.log(pickupData.uid);
                    shipment.id && storePickupData(pickupData, shipment.id);

                    setOpen(false);
                    toast({ title: "Pickup scheduled", description: `Your pickup is scheduled!`, variant: "success" });
                    setShipment(shipment);
                    setPickup(pickupData);
                })
                .catch((e) => {
                    console.error(e);

                    const description = handlePickupErrorToastDescription(e, shipment.serviceDetails?.carrier);

                    toast({ title: "Pickup not scheduled", description, variant: "destructive" });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen} modal={true}>
                <DialogContent
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                    <DialogHeader>
                        <DialogDescription>
                            <p>To edit the pickup, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>
                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-2">
                        <CreatePickup />
                        <DialogFooter>
                            <LoadingButton isLoading={loading} type="submit">
                                Schedule Pickup
                            </LoadingButton>
                        </DialogFooter>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

const ExistingPickupDialog = ({ shipment, open, setOpen, setShipment, setPickup }: DialogProps) => {
    const [selectedPickup, setSelectedPickup] = useState<PickupData | undefined>();

    const handleClick = async () => {
        await addPickupToShipment(selectedPickup?.id, shipment.id);
        shipment.pickupDetails.id = selectedPickup?.id;
        setShipment(shipment);
        setPickup(selectedPickup);
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="w-full">
                    <UseExistingPickup shipment={shipment} selectedPickup={selectedPickup} setSelectedPickup={setSelectedPickup} />
                    <DialogFooter>
                        <Button className="w-full" onClick={handleClick}>
                            Add to existing pickup
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};
