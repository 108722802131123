import { Button } from "@/components/ui/button";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { CircleCheckBig, Info } from "lucide-react";

import { useState, useEffect } from "react";
import { useFormStore } from "@/core/ShipmentFormStore";

import LabelsDetails from "@/components/composite/LabelsDetails";
import OrderDetails from "@/components/composite/OrderDetails";
import PickupDetails from "@/components/composite/Cards/PickupDetails";

import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/core/firebase";
import { Shipment } from "@shared/ShipmentTypes";

import { Skeleton } from "@/components/ui/skeleton";
import { useUserStore } from "@/core/UserStore";
import { useNavigate, useParams } from "react-router-dom";
import { getPickupByID } from "@/api/pickups/getPickup";
import { PickupData } from "@shared/PickupTypes";
import SchedulePickup from "@/components/composite/Cards/SchedulePickup";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

export default function ShipmentSuccess() {
    let { id } = useParams();
    const navigate = useNavigate();
    const user = useUserStore((state) => state.user);
    const [shipment, setShipment] = useState<Shipment>({} as Shipment);
    const [pickup, setPickup] = useState<PickupData>({} as PickupData);
    const [loading, setLoading] = useState(true);
    const form = useFormStore()

    useEffect(() => {
        if (id && user) {
            const unsub = onSnapshot(doc(db, "shipments", id), (doc) => { // needed since redirected from Stripe Pay Now whose callback should create the shipment, but we land on this page before that happens
                if (doc.exists()) {
                    const shipment = doc.data() as Shipment;
                    // console.log(shipment);
                    useFormStore.getState().removeShipment();
                    setShipment(shipment);
                    if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id) {
                        getPickupByID(shipment.pickupDetails.id)
                            .then((pickupData: PickupData) => {
                                setPickup(pickupData);
                            })
                            .catch((error) => {
                                throw error;
                            });
                    }

                    setLoading(false);
                    unsub(); // only run once and then remove the listener, otherwise random updates to doc will cause updates to this component
                }
            });

            return () => {
                unsub();
            };
        }
    }, [id, user]);

    const newOrder = () => {
        navigate("/create-shipment", { replace: true });
    };

    const createReturnShipment = () => {
        if (!shipment || !shipment.receiver) return;

        const returnShipment = structuredClone(shipment)

        const shipper = returnShipment.shipper
        const receiver = returnShipment.receiver[0]
        const shipmentDetails = returnShipment.shipmentDetails

        form.addShipper(receiver)
        form.addReceiver(shipper)

        shipmentDetails.returnDetails = {
            isReturnShipment: true,
            originalShipmentId: shipment.id,
        }
        form.addShipmentDetails(shipmentDetails)

        const ratesCarrier = shipment.serviceDetails!.carrier
        navigate(`/create-return-shipment?ignoreAddressDefaults=true&ratesCarrier=${ratesCarrier}`, { replace: true });
    };

    return (
        <>
            {!loading ? (
                <>
                    <PageTitle>
                        <CircleCheckBig />
                        {shipment ? shipment.id : <Skeleton className="h-8 w-20" />} Confirmed!
                    </PageTitle>
                    <div className="flex gap-6">
                        <div className="flex w-1/3 flex-col gap-6">
                            {pickup.pickupDetails && <PickupDetails pickupData={pickup} shipment={shipment} setShipment={setShipment} setPickup={setPickup} />}
                            {shipment.pickupDetails.id === null && <SchedulePickup shipment={shipment} setShipment={setShipment} setPickup={setPickup} />}
                            <LabelsDetails shipment={shipment} />
                            <Button variant="default" onClick={newOrder}>
                                Create New Shipment
                            </Button>
                            {!shipment?.shipmentDetails?.returnDetails?.isReturnShipment &&
                                (
                                    <div className="grid grid-cols-12">
                                        <Button variant="default" onClick={createReturnShipment} className="col-span-11">
                                            Create Return Shipment
                                        </Button>
                                        <TooltipProvider delayDuration={50} >
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <Info className="h-4 ml-2" />
                                                </TooltipTrigger>
                                                <TooltipContent className="w-72 text-left">
                                                    <p>Auto-populate your return shipment details by clicking this button. We currently do not do this automatically for you to ensure you have the autonomy to select your desired rate and transit time.</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                )
                            }
                        </div>
                        <div className="flex-1">{shipment && <OrderDetails type="complete" shipment={shipment} />}</div>
                    </div>
                </>
            ) : (
                <Skeleton className="h-full w-full" />
            )}
        </>
    );
}
