import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import SectionHeader from "../../../components/composite/Headers/SectionHeader";
import { userSchema } from "@/schema/UserSchema";
import { getUserbyEmail } from "@/api/user/getUser";
import { auth } from "@/core/firebase";
import { useState, useEffect } from "react";
import { UserData } from "@shared/UserTypes";
import { updateUserInfo } from "@/api/user/updateUser";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { useUserStore } from "@/core/UserStore";

export default function InfoForm() {
    const [userInfo, setUserInfo] = useState<UserData | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const userData = useUserStore((state) => state.userData);
    useEffect(() => {
        setUserInfo(userData);
    }, [userData]);

    const form = useForm<z.infer<typeof userSchema>>({
        resolver: zodResolver(userSchema),
        defaultValues: {
            name: userInfo?.name || "",
            email: userInfo?.email || "",
            companyName: userInfo?.companyName || "",
            phoneNumber: userInfo?.phoneNumber || ""
        }
    });

    async function onSubmit(data: z.infer<typeof userSchema>) {
        const updatedFields: Partial<z.infer<typeof userSchema>> = {};

        for (const key in data) {
            if (userInfo && data[key] !== "" && data[key] !== userInfo[key]) {
                updatedFields[key] = data[key];
            }
        }

        if (Object.keys(updatedFields).length > 0) {
            setIsLoading(true);
            await updateUserInfo(auth.currentUser?.email, updatedFields)
                .then(async () => {
                    toast({
                        title: "Success!",
                        description: "Your information has been updated.",
                        className: "bg-success-400"
                    });
                    const updatedUserInfo = await getUserbyEmail(auth.currentUser?.email);
                    setUserInfo(updatedUserInfo);
                    form.reset();
                })
                .catch(() => {
                    toast({
                        title: "Error occured!",
                        description: "Your information was unable to get updated.",
                        className: "bg-error-400"
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    return (
        <>
            <Form {...form}>
                <SectionHeader title="Account Information" description="Edit your account information here." />

                <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid grid-cols-2 gap-8 space-y-6 md:space-y-0">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input placeholder={userInfo?.name} {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input disabled={true} placeholder={userInfo?.email} {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Phone Number</FormLabel>
                                <FormControl>
                                    <Input placeholder={userInfo?.phoneNumber} {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="companyName"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Company Name</FormLabel>
                                <FormControl>
                                    <Input placeholder={userInfo?.companyName} {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <LoadingButton isLoading={isLoading} type="submit" disabled={!form.formState.isDirty} className="w-full md:w-auto">
                        Save
                    </LoadingButton>
                </form>
            </Form>
        </>
    );
}
