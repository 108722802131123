import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import Header from "./Header";
import NavBar from "./NavigationMenu";
import { useUserStore } from "@/core/UserStore";
import { useAppContext } from "@/onboarding/context";
import { useMount } from "react-use";
import { BannerWrapper } from "../composite/Banner";
import { Button } from "../ui/button";
import { Menu, Undo2 } from "lucide-react";

export default function MainLayout() {
    const user = useUserStore((state) => state.user);
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);
    // const loading = useUserStore((state) => state.loading);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 768px is the breakpoint for mobile screen widths
    const location = useLocation();
    const currentRoute = location.pathname.split("/")[1];
    const [isNavOpen, setIsNavOpen] = useState(false); // for mobile, open/close sidebar

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login", { replace: true });
        }
    }, [user]);

    const {
        setState,
        state: { tourActive }
    } = useAppContext();

    useMount(() => {
        if (tourActive) {
            setState({ run: true, stepIndex: 3 });
        }
    });

    // update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isMobile && (currentRoute === "create-shipment" || currentRoute === "create-return-shipment")) {
        return (<div className="flex flex-col gap-4 h-screen items-center justify-center p-4 text-center lg:hidden w-screen">
            This page has not been optimized for smaller devices yet. Please consider using a larger device.
            <Button onClick={() => navigate("/", { replace: true })}>
                <Undo2 className="w-4 h-4 mr-2" />
                Back
            </Button>
        </div>)
    }

    if (isMobile) {
        return (
            <div className="h-screen w-screen max-w-full h-dvh overflow-x-hidden">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <Header />
                <div className="p-4 md:p-6 w-full max-w-full overflow-x-hidden" id="third-step">
                    <BannerWrapper />
                    <Button variant="outline" size="icon" className="md:hidden mb-4" onClick={() => setIsNavOpen(!isNavOpen)}>
                        <Menu />
                    </Button>
                    <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity ${isNavOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={() => setIsNavOpen(!isNavOpen)}>
                        <div className={`fixed left-0 top-0 h-full w-64 bg-white shadow-lg transition-transform transform duration-300 ${isNavOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                            <Header showAccountIcon={false} />
                            <NavBar isOpen={isNavOpen} setIsOpen={setIsNavOpen} />
                        </div>
                    </div>
                    <Outlet />
                </div>
                <Toaster />
                <Sonner />
                <div className="h-24">
                </div>
            </div>
        )
    }

    return (
        <div className={`${isMobile && "hidden"} h-full w-full grid-rows-[76px_1fr] overflow-hidden grid`}>
            <Header />
            <div className="grid grid-cols-[204px_1fr] overflow-hidden">
                <NavBar />
                <div className="overflow-y-auto p-6" id="third-step">
                    {/* <Banner>Test</Banner> */}
                    <BannerWrapper />
                    <Outlet />
                </div>
            </div>
            <Toaster />
            <Sonner />
        </div>
    );
}