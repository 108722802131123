import { z } from "zod";
import { addressSchema, dateSchema } from "./ShipmentSchema";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { parsePhoneNumber } from "awesome-phonenumber";
dayjs.extend(isBetween);

// const shipmentSchema = z.object({
//     carrier: z.string(),
//     shipmentID: z.array(z.string())
// });

// export const createPickupSchema = z.intersection(shipmentSchema, pickupDetailsSchema);

// export const createPickupSchema = z.intersection(shipmentSchema, pickupDetailsSchema);

export const createPickupSchema = (z
    .object({
        id: z.string().optional(),
        carrier: z.string(), //enum(["UPS", "Purolator", "FedEx", "GLS"]),

        data: z.string().optional(), // used in edit

        phoneNumber: z.string(),
        companyName: z.string().min(1, "Company Name is required").max(34, "Company Name is too long").optional().or(z.literal("")),
        attentionName: z.string().min(1, "Attention Name is required").max(34, "Attention Name is too long"),
        email: z.string().email().or(z.literal("")).optional(), // allow undefined, empty string, and valid email strings
        address: addressSchema,

        // pickupDetails
        pickupLocation: z.string().min(1, "Pickup Location is required"),
        readyDate: z.date(),
        readyTime: z.date(),
        closeTime: z.date(),
        specialNote: z.string().optional(),

        // // needed for default handling in create/edit pickup modals
        notifications: z.any().optional(),
        defaultShipper: z.boolean().optional(),
        defaultReceiver: z.boolean().optional(),
        defaultPickup: z.boolean().optional(),

    })
    .superRefine((data, ctx) => {
        if (!(["UPS", "Purolator", "FedEx", "GLS"].includes(data.carrier))) {
            if (data.phoneNumber.startsWith("+")) {
                ctx.addIssue({
                    code: "custom",
                    message: "Carrier selection required",
                    path: ["carrier"],
                    fatal: true
                });
            }
        }
    })
    .superRefine((data, ctx) => {
        if (parsePhoneNumber(data.phoneNumber).valid === false) {
            if (data.phoneNumber.startsWith("+")) {
                ctx.addIssue({
                    code: "custom",
                    message: "Please add a valid phone number",
                    path: ["phoneNumber"],
                    fatal: true
                });
            } else {
                ctx.addIssue({
                    code: "custom",
                    message: "Please verify that you have included a country code (e.g., '+1')",
                    path: ["phoneNumber"],
                    fatal: true
                });
            }
        }
    })
    .superRefine((data, ctx) => {
        if (!data.readyTime) {
            ctx.addIssue({
                code: "custom",
                message: "Missing ready time",
                path: ["readyTime"]
            });
        }
        if (!data.closeTime) {
            ctx.addIssue({
                code: "custom",
                message: "Missing close time",
                path: ["closeTime"]
            });
        }

        const readyTime = dayjs(data.readyTime);
        const closeTime = dayjs(data.closeTime);

        if (readyTime.isAfter(closeTime)) {
            ctx.addIssue({
                code: "custom",
                message: "Ready time must be before close time.",
                path: ["readyTime"]
            });
        }

        switch (data.carrier) {
            case "UPS":
                if (closeTime.isBefore(closeTime.startOf("day").set("hour", 13))) {
                    ctx.addIssue({
                        code: "custom",
                        message:
                            "Please set the pick-up window close time to after 1:00 PM, as per UPS guidelines. For reference, UPS typically completes drop-offs in the morning and schedules pick-ups in the afternoon.",
                        path: ["closeTime"]
                    });
                }
                if (closeTime.diff(readyTime, "hour", true) < 3) {
                    ctx.addIssue({
                        code: "custom",
                        message: "UPS requires a 3-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                        path: ["closeTime"]
                    });
                }
                break;
            case "Purolator":
                if (closeTime.diff(readyTime, "hour", true) < 2) {
                    ctx.addIssue({
                        code: "custom",
                        message: "Purolator requires a minimum 2-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                        path: ["closeTime"]
                    });
                }
                break;
            case "FedEx":
                if (readyTime.isAfter(readyTime.startOf("day").set("hour", 17))) {
                    ctx.addIssue({
                        code: "custom",
                        message: "Please ensure the pick-up window 'ready time' is set before 5:00 PM, as required by FedEx.",
                        path: ["readyTime"]
                    });
                }
                if (closeTime.diff(readyTime, "hour", true) < 1.5) {
                    ctx.addIssue({
                        code: "custom",
                        message: "FedEx requires a minimum 1.5-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                        path: ["closeTime"]
                    });
                }
                break;
            case "GLS":
                if (readyTime.isAfter(readyTime.startOf("day").set("hour", 20).set("minute", 30))) {
                    ctx.addIssue({
                        code: "custom",
                        message: "Please ensure the pick-up window 'ready time' is set before 8:30 PM, as required by GLS.",
                        path: ["readyTime"]
                    });
                }
                if (closeTime.isAfter(closeTime.startOf("day").set("hour", 22).set("minute", 30))) {
                    ctx.addIssue({
                        code: "custom",
                        message: "Please ensure the pick-up window 'close time' is set before 10:30 PM, as required by GLS.",
                        path: ["closeTime"]
                    });
                }
                if (closeTime.diff(readyTime, "hour", true) < 1.5) {
                    ctx.addIssue({
                        code: "custom",
                        message: "GLS requires a 1.5-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                        path: ["closeTime"]
                    });
                }
                break;

            default:
                break;

        }
    })


);
