import { camelCaseToTitleCase } from "@/lib/utils";
import { Address, Customer } from "@shared/ShipmentTypes";
import { House } from "lucide-react";

interface ShippingAddressProps {
    title: string;
    customer: Customer;
}

export default function ShippingAddress({ title, customer }: ShippingAddressProps) {
    const { address, attentionName, companyName } = customer;
    return (
        <div className="w-full">
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
            <div className="space-y-1 text-gray-500">
                <p>{attentionName}</p>
                {(companyName && companyName != attentionName) && <p>{companyName}</p>}
                <p>{address.street}</p>
                {address.line2 && <p>{address.line2}</p>}
                <p>{`${address.city}, ${address.stateCode} ${address.postalCode}`}</p>
                {address.residential &&
                    <div className="flex items-center text-gray-500 w-full text-sm mt-4">
                        <House className="mr-2 h-4 w-4" /> Residential Address
                    </div>
                }
            </div>


        </div>
    );
}
