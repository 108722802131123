import PageTitle from "@/components/composite/Headers/PageTitle";
import { Package } from "lucide-react";
import { useLocation } from "react-router-dom";
import { ShipmentsTable } from "./ShipmentsTable";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient()

export default function ShipmentsLayout() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const statusFilter = queryParams.get("status") || "";

    const initialColumnFiltersState: [{ id: string, value: string }] = [{ id: "status", value: statusFilter }]

    return (
        <>
            <PageTitle>
                <Package />
                Shipments
            </PageTitle>
            {/* <DataTable columns={columns} data={data} refreshData={fetchData} loading={loading} page={page} pageSize={pageSize} setPage={_setPage} setPageSize={_setPageSize} filterStatuses={filterStatuses}/> */}
            <QueryClientProvider client={queryClient}>
                <ShipmentsTable initialColumnFiltersState={initialColumnFiltersState} pageSize={window.innerWidth < 768 ? 30 : 10} />
            </QueryClientProvider>
        </>
    );
}
