import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

import { useFormStore } from "@/core/ShipmentFormStore";
import CarrierLogo from "@/components/composite/CarrierLogo";
import { RatesResponse } from "@/types/ResponseTypes";
import { useState, useRef } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { titleCase } from "@/lib/utils";

interface RatesCard {
    Rates: RatesResponse;
    selectedRate?: string;
    setSelectedRate?: React.Dispatch<React.SetStateAction<string>>;
}

export default function RatesCard({ Rates, selectedRate, setSelectedRate }: RatesCard) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const tooltipTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        tooltipTimeout.current = setTimeout(() => {
            setTooltipVisible(true);
        }, 300);
    };

    const handleMouseLeave = () => {
        if (tooltipTimeout.current) {
            clearTimeout(tooltipTimeout.current);
            tooltipTimeout.current = null;
        }
        setTooltipVisible(false);
    };

    const handleClick = () => {
        useFormStore.getState().addServiceDetails({
            serviceName: Rates.ServiceName,
            carrier: Rates.carrier,
            serviceCode: Rates.ServiceCode,
            transitTimes: Rates.transitTimes
        });

        useFormStore.getState().editData({ cost: Rates.cost });
        useFormStore.getState().editData({ costBreakdown: Rates.costBreakdown });

        if (setSelectedRate) {
            setSelectedRate(Rates.ServiceCode);
        }
    };

    return (
        <div role="rate">
            <Card className={selectedRate === Rates.ServiceCode ? "border-2 border-solid border-gray-600" : ""}>
                <CardHeader className="gap-4">
                    <CardTitle className="flex h-3.5 items-center gap-3 text-sm">
                        <CarrierLogo carrier={Rates.carrier} className={"h-full object-cover "} />
                        {Rates.ServiceName}
                    </CardTitle>
                    <CardDescription className="">
                        {Rates.transitTimes !== 0 ? <>{Rates.transitTimes === 1 ? "Next Day Delivery" : `${Rates.transitTimes} Business Days`}</> : "Transit time unavailable"}
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <TooltipProvider delayDuration={300}>
                        <Tooltip open={tooltipVisible}>
                            <TooltipTrigger className="w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <Button variant={selectedRate === Rates.ServiceCode ? "default" : "secondary"} className="flex w-full gap-1 text-base" onClick={handleClick}>
                                    <p className="font-bold">${Rates.cost.toFixed(2)}</p> <p className="line-through">${Number(Rates.originalCost).toFixed(2)}</p>
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                {Rates.costBreakdown && Rates.costBreakdown !== "Flat Rate" ? (
                                    <Table>
                                        <TableBody>
                                            {Rates.costBreakdown.flatMap(Object.keys).map((cost, index) => {
                                                if (!Rates.costBreakdown) {
                                                    return;
                                                }
                                                if (Rates.costBreakdown[index][cost] === 0) {
                                                    return;
                                                }
                                                // * breaks down the key and makes it into a readable string
                                                const surchargeName = cost
                                                    .split(/(?=[A-Z])/)
                                                    .map((str) => titleCase(str))
                                                    .join(" ");

                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{surchargeName}</TableCell>
                                                        <TableCell>{Rates.costBreakdown && Rates.costBreakdown[index][cost].toFixed(2)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <p className="p-4">Flat Rate Shipping Applied</p>
                                )}
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </CardContent>
            </Card>
        </div>
    );
}
