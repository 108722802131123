export const privacySections = {
    introduction: {
      title: "PRIVACY POLICY",
      lastUpdated: "February 26, 2025",
      content: `This Privacy Notice for SnapShip describes how and why we might access, collect, store, use, and/or share your personal information when you use our services, including when you:`,
      bullets: [
        "Visit our website at https://app.snapship.co/, or any website of ours that links to this Privacy Notice",
        "Engage with us in other related ways, including any sales, marketing, or events",
      ],
      contact:
        "Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@snapship.co.",
    },
    keyPoints: {
      title: "SUMMARY OF KEY POINTS",
      points: [
        {
          title: "What personal information do we process?",
          content:
            "When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use.",
        },
        {
          title: "Do we process any sensitive personal information?",
          content: "We do not process sensitive personal information.",
        },
        {
          title: "Do we collect any information from third parties?",
          content: "We do not collect any information from third parties.",
        },
        {
          title: "How do we process your information?",
          content:
            "We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.",
        },
      ],
    },
    informationCollection: {
      title: "1. WHAT INFORMATION DO WE COLLECT?",
      sections: [
        {
          subtitle: "Personal information you disclose to us",
          shortDescription: "In Short: We collect personal information that you provide to us.",
          content:
            "We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.",
          personalInfo: {
            title: "Personal Information Provided by You",
            content:
              "The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:",
            items: ["names", "phone numbers", "email addresses", "usernames", "passwords", "mailing addresses"],
          },
          sensitiveInfo: {
            title: "Sensitive Information",
            content: "We do not process sensitive information.",
          },
          paymentInfo: {
            title: "Payment Data",
            content:
              "We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Stripe. You may find their privacy notice link here: https://stripe.com/en-ca/privacy.",
          },
        },
      ],
    },
    informationProcessing: {
      title: "2. HOW DO WE PROCESS YOUR INFORMATION?",
      shortDescription:
        "In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.",
      purposes: [
        "To facilitate account creation and authentication and otherwise manage user accounts",
        "To deliver and facilitate delivery of services to the user",
        "To respond to user inquiries/offer support to users",
        "To send administrative information to you",
        "To fulfil and manage your orders",
      ],
    },
    legalBases: {
      title: "3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?",
      shortDescription:
        "In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.",
      exceptions: [
        "If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way",
        "For investigations and fraud detection and prevention",
        "For business transactions provided certain conditions are met",
        "If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim",
        "For identifying injured, ill, or deceased persons and communicating with next of kin",
        "If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse",
        "If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province",
        "If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records",
        "If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced",
        "If the collection is solely for journalistic, artistic, or literary purposes",
        "If the information is publicly available and is specified by the regulations",
      ],
    },
  }  