import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { arrayRemove, doc, setDoc, updateDoc, deleteField } from "firebase/firestore";
import { db } from "@/core/firebase";
import { Button } from "@/components/ui/button";
import dayjs from "dayjs";

import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import CreatePickup from "@/app/shipment/Pickups/CreatePickup";
import { pickupDetailsSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { PickupData, PickupStatus } from "@shared/PickupTypes";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Dialog, DialogContent, DialogDescription, DialogHeader } from "@/components/ui/dialog";
import { toast, useToast } from "@/components/ui/use-toast";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import Form from "@/components/composite/Form";
import { Info } from "lucide-react";
import { TooltipContent, TooltipProvider, TooltipTrigger, Tooltip } from "@/components/ui/tooltip";
import { handlePickupErrorToastDescription } from "@/lib/errorHandling";
import { Shipment } from "@shared/ShipmentTypes";
import { createPickup } from "@/api/pickups/createPickup";
import { Carrier } from "@shared/Carrier";
import { CustomAlertDialog } from "../CustomAlertDialog";

interface PickupDetails {
    pickupData: PickupData;
    shipment: Shipment;
    setPickup: Function;
    setShipment: Function;
}

export const PickupLocation = {
    frontDoor: "Front door",
    backEntrance: "Back entrance",
    mainLobby: "Main lobby",
    rearEntrance: "Rear entrance",
    sideEntrance: "Side entrance",
    receptionDesk: "Reception desk",
    mailroom: "Mailroom",
    conferenceRoom: "Conference room",
    employeeEntrance: "Employee entrance",
    loadingDock: "Loading dock",
    securityDesk: "Security desk",
    visitorCenter: "Visitor center",
    stairwell: "Stairwell",
    elevatorLobby: "Elevator lobby",
    warehouseEntrance: "Warehouse entrance",
    garageEntrance: "Garage entrance"
};

export default function PickupDetails({ pickupData, shipment, setPickup, setShipment }: PickupDetails) {
    const [open, setOpen] = useState(false); // open edit pickup dialog
    const [cancelled, setCancelled] = useState(false); // if pickup is cancelled

    const { toast } = useToast();

    const handleCancel = async () => {
        cancelPickup(pickupData)
            .then(async (response) => {
                console.log(response);
                await updatePickupStatus(pickupData.id, shipment.id, { status: "Cancelled" });
                await removePickupFromShipment(shipment.id);
                toast({ title: "Pickup Cancelled", description: "Please schedule another pickup or drop off your shipment" });
                setCancelled(true);

                setPickup({});
                shipment.pickupDetails = { id: null, pickupType: "pickup" };
                setShipment(shipment);
            })
            .catch((e) => {
                console.error(e);
                toast({ title: "Pickup not Cancelled", description: "Please try again", variant: "destructive" });
            });
    };

    const handleRemove = async () => {
        await removePickupFromShipment(shipment.id);
        toast({ title: "Pickup Removed", description: "Please schedule a pickup or add to another pickup" });
        setCancelled(true);

        shipment.pickupDetails = { id: null, pickupType: "pickup" };
        setShipment(shipment);
        setPickup({});
    };

    return (
        <>
            <Card className="h-fit border-2">
                <CardHeader>
                    <CardTitle>Pickup-ID: {pickupData.id}</CardTitle>
                    <CardDescription>
                        {/* {shipment.pickupDetails.readyTimeStamp?.date} at {shipment.pickupDetails.readyTimeStamp?.time} */}
                        <span>
                            {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to{" "}
                            {dayjs(pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}
                        </span>
                        <br />
                        <div className="flex items-center">
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Info className="mr-2 w-4" />
                                    </TooltipTrigger>
                                    <TooltipContent className="max-w-xs font-normal">
                                        <p className="">
                                            Please note that if you do not see a pick-up confirmation number, your pick-up is not confirmed. Try scheduling the pick-up again or email{" "}
                                            <a className="cursor-pointer text-blue-500 underline" href="mailto:info@snapship.co">
                                                info@snapship.co
                                            </a>{" "}
                                            for further support.
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            <p className="font-bold">
                                Confirmation Number: <span className="font-normal">{pickupData.data}</span>
                            </p>
                        </div>
                    </CardDescription>
                </CardHeader>
                <CardContent className="grid gap-4">
                    {pickupData.shipper && (
                        <div>
                            <p>{pickupData.shipper.address.street}</p>
                            <p>
                                {pickupData.shipper.address.city} {pickupData.shipper.address.stateCode} {pickupData.shipper.address.postalCode}
                            </p>
                            <p>{pickupData.pickupDetails.pickupLocation && PickupLocation[pickupData.pickupDetails.pickupLocation]}</p>
                            {pickupData.pickupDetails.specialNote && (
                                <p className="text-sm font-bold">
                                    Note for the Driver: <span className="font-normal">{pickupData.pickupDetails.specialNote}</span>
                                </p>
                            )}
                        </div>
                    )}
                    <>
                        {/* <div className="grid grid-cols-2 gap-2"> */}
                        {/* <Button variant={"secondary"} onClick={() => setOpen(!open)} disabled={cancelled}>
                            Edit
                        </Button> */}
                        <CustomAlertDialog title="Are you sure?" description="This action cannot be undone. This will permanently cancel this pickup." onConfirm={handleCancel} resolved={cancelled}>
                            <Button className="w-full" variant={"destructive"} disabled={cancelled}>
                                Cancel
                            </Button>
                        </CustomAlertDialog>

                        {pickupData.shipmentID.length > 1 && (
                            <Button variant={"outline"} onClick={handleRemove} disabled={cancelled} className="col-span-2">
                                Remove Pickup
                            </Button>
                        )}
                        {/* </div> */}
                    </>
                </CardContent>
            </Card>
            <EditPickupDialog pickupData={pickupData} open={open} setOpen={setOpen} shipment={shipment} setPickup={setPickup} setShipment={setShipment} />
        </>
    );
}

interface EditPickupDialog {
    pickupData: PickupData;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    shipment: Shipment;
    setPickup: Function;
    setShipment: Function;
}

const EditPickupDialog = ({ pickupData, open, setOpen, shipment, setPickup, setShipment }: EditPickupDialog) => {
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<z.infer<typeof pickupDetailsSchema>>({
        resolver: zodResolver(pickupDetailsSchema),
        mode: "onChange",
        defaultValues: {
            pickupType: "pickup",
            scheduleType: "scheduleNow",
            carrier: pickupData?.carrier as Carrier | undefined,
            pickupLocation: pickupData.pickupDetails.pickupLocation,
            readyTimeStamp: {
                date: pickupData.pickupDetails.readyTimeStamp?.date,
                time: pickupData.pickupDetails.readyTimeStamp?.time,
                isoTimeStamp: pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp
            },
            closeTimeStamp: {
                date: pickupData.pickupDetails.closeTimeStamp?.date,
                time: pickupData.pickupDetails.closeTimeStamp?.time,
                isoTimeStamp: pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp
            }
        }
    });

    const handleSubmit = async (data: z.infer<typeof pickupDetailsSchema>) => {
        setIsLoading(true);

        const updatedPickupData = { ...pickupData, pickupDetails: data, status: "Scheduled" as PickupStatus }; // make copy to prevent auto change of ready/close times despite a failure

        const shipmentRef = doc(db, "shipments", shipment.id as string);

        // TODO: move all this logic to the backend, should be a single call

        const cancelStatus = await cancelPickup(updatedPickupData);

        if (cancelStatus === "Pickup not cancelled") {
            toast({ title: "Pickup not Rescheduled", description: "Could not cancel pickup", variant: "destructive" });
            setOpen(false);
            setIsLoading(false);
            return;
        }

        // cancel pickup on our end. this is needed in case the create fails below and we dont end up in an invalid state showing a pickup thats been cancelled.
        try {
            await setDoc(doc(db, "pickups", updatedPickupData.id as string), { status: "Cancelled", shipmentID: arrayRemove(shipment.id) }, { merge: true });
            await updateDoc(shipmentRef, {
                "pickupDetails.id": null // required to show the pickup details in shipment details page
            });
        } catch (e) {
            console.error("Error updating firebase cancelling pickup:", e);
            toast({ title: "Pickup not Rescheduled", description: "Service unavailable, please try again", variant: "destructive" });

            setOpen(false);
            setIsLoading(false);
            return;
        }

        try {
            shipment.pickupDetails = updatedPickupData.pickupDetails;
            updatedPickupData.data = await createPickup(shipment);
        } catch (error) {
            console.error("Error creating pickup through API:", error);

            const description = handlePickupErrorToastDescription(error, updatedPickupData?.carrier);

            toast({ title: "Pickup not Rescheduled", description, variant: "destructive" });

            setOpen(false);
            setIsLoading(false);
            shipment.pickupDetails.id = null;
            setPickup({});
            setShipment(shipment);
            return;
        }

        try {
            await updateDoc(shipmentRef, {
                "pickupDetails.id": updatedPickupData.id
            });

            const pickupRef = doc(db, "pickups", updatedPickupData.id as string);
            await updateDoc(pickupRef, {
                ...updatedPickupData,
                shipmentID: [shipment.id]
            });
        } catch (e) {
            // TODO: how to handle when this fails?

            console.error("Error updating firebase with new pickup info:", e);

            toast({ title: "Pickup not Rescheduled", description: "Service unavailable, please try again", variant: "destructive" });

            setOpen(false);
            setIsLoading(false);
            return;
        }

        toast({
            title: "Pickup Rescheduled",
            description: `Your pickup is now rescheduled for ${dayjs(updatedPickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from ${dayjs(updatedPickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to ${dayjs(pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}`
        });
        setOpen(false);
        setIsLoading(false);
        setPickup(updatedPickupData);
        setShipment(shipment);
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent>
                    <DialogHeader>
                        {/* <DialogTitle></DialogTitle> */}
                        <DialogDescription>
                            <p>To edit the pickup, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>
                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-2">
                        <CreatePickup />
                        <LoadingButton isLoading={isLoading} type="submit">
                            Reschedule Pickup
                        </LoadingButton>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};
