import { z } from "zod";

export const userSchema = z.object({
    // username: z.string().optional(),
    name: z.string(),
    // firstName: z.string().optional(),
    // lastName: z.string().optional(),
    email: z.string().optional(),
    phoneNumber: z.string().regex(/^\d+$/, "Phone number must contain only numbers").optional(),
    companyName: z.string().optional()
});

export const signUpSchema = z
    .object({
        name: z.string().min(1, "Name is required."),
        companyName: z.string().min(1, "Company name is required."),
        email: z.string().email(),
        phoneNumber: z.string().min(1, "Phone number is required."),
        password: z.string().min(8, "Password must contain at least 8 character(s)"),
        confirmPassword: z.string().min(8, "Password must contain at least 8 character(s)"),
        partnershipCode: z.string().optional()
    })
    .refine((data) => data.password === data.confirmPassword, { message: "Passwords don't match", path: ["confirmPassword"] });

export const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8, "Password must contain at least 8 character(s)")
});

export const resetSchema = z.object({
    email: z.string().email()
});

export const carrierSchema = z.object({
    UPS: z.boolean(),
    FedEx: z.boolean(),
    Purolator: z.boolean(),
    GLS: z.boolean()
});
