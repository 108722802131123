import Logout from "@/app/auth/LogoutButton";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";
import { NavLink, useNavigate } from "react-router-dom";
import { Home, Clipboard, Package, LineChart, LucideProps, Settings2, CircleUser, LogOut, Truck, BookUser, ShoppingCart, Undo2, ChevronDown } from "lucide-react";
import { createElement, useState } from "react";
import { useUserStore } from "@/core/UserStore";
import { Button } from "../ui/button";
import { Separator } from "@/components/ui/separator";

interface Pages {
    icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>;
    title: string;
    link: string;
    subPages?: Pages[];
}

const topPages: Pages[] = [
    {
        icon: Home,
        title: "Home",
        link: "/"
    },
    {
        icon: Clipboard,
        title: "Create Shipment",
        link: "/create-shipment",
        subPages: [
            {
                icon: Undo2,
                title: "Create Return Shipment",
                link: "/create-return-shipment"
            }
        ]
    },
    {
        icon: Package,
        title: "Shipments",
        link: "/shipments"
    },
    {
        icon: BookUser,
        title: "Addresses",
        link: "/address"
    },
    {
        icon: Truck,
        title: "Carriers",
        link: "/settings/carriers"
    }
];

const bottomPages: Pages[] = [
    {
        icon: Settings2,
        title: "Settings",
        link: "/settings"
    },
    {
        icon: CircleUser,
        title: "Support",
        link: "/support"
    }
];

export default function NavBar({ isOpen, setIsOpen }: { isOpen?: boolean, setIsOpen?: (isOpen: boolean) => void }) {
    const user = useUserStore((state) => state.user);
    const logout = useUserStore((state) => state.logout);
    const navigate = useNavigate();

    async function handleLogout() {
        try {
            logout();
            navigate("/login", { replace: true });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="min-h-full relative flex flex-col border-r bg-slate-100/30" onClick={(e) => e.stopPropagation()}>
                <div className="mt-6 flex flex-col gap-4 px-4">
                    <ListPages pages={topPages} isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
                <div className="mb-6 mt-auto flex flex-col gap-4 px-4 md:top-0 -top-16 md:static relative">
                    <Separator />
                    <ListPages pages={bottomPages} isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
            </div>
        </>
    );
}

const ListPages = ({ pages, isOpen, setIsOpen }: { pages: Pages[], isOpen?: boolean, setIsOpen?: (isOpen: boolean) => void }) => {
    const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});

    const toggleExpand = (title: string) => {
        setExpandedItems(prev => ({
            ...prev,
            [title]: !prev[title]
        }));
    };

    return pages.map((page, index) => (
        <div key={index} className="flex flex-col">
            <div className="flex items-center">
                <NavLink
                    to={page.link}
                    className={({ isActive }) =>
                        isActive
                            ? "flex flex-1 items-center gap-3 rounded-lg bg-slate-100 px-3 py-3"
                            : "hover:text-primary flex flex-1 items-center gap-3 rounded-lg bg-transparent px-3 py-3 text-gray-500"
                    }
                    replace={true} onClick={setIsOpen && page.title !== "Support" ? () => setIsOpen(!isOpen) : undefined}>
                    <div className="flex items-center gap-2">
                        {createElement(page.icon, { className: "h-4 w-4" })}
                        {page.title}
                        {page.title === "Home" && (
                            <span className="rounded bg-primary px-1.5 py-0.5 text-[10px] font-medium leading-none text-primary-foreground">
                                NEW
                            </span>
                        )}
                        {page.subPages && (
                            <Button
                                variant="ghost"
                                size="sm"
                                className="p-0 hover:bg-transparent"
                                onClick={() => toggleExpand(page.title)}
                            >
                                <ChevronDown
                                    className={`h-4 w-4 transition-transform duration-200 ${expandedItems[page.title] ? 'rotate-180' : ''
                                        }`}
                                />
                            </Button>
                        )}
                    </div>
                </NavLink>
            </div>
            {page.subPages && expandedItems[page.title] && (
                <div className="ml-6 mt-1">
                    <ListPages pages={page.subPages} />
                </div>
            )}
        </div>
    ));
};