import { createRefund } from "@/api/payments/createRefund";
import { getPickupByShipmentID } from "@/api/pickups/getPickup";
import { cancelPickup, updatePickupStatus, removePickupFromShipment } from "@/api/pickups/updatePickups";
import { voidShipment } from "@/api/shipments/updateShipment";
import { Carrier } from "@shared/Carrier";
import { Shipment } from "@shared/ShipmentTypes";

export const voidShipmentHelper = async (shipment: Shipment) => {
    try {
        // Check if the shipment has a pickup scheduled; if it does, cancel it
        if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id !== null) {
            const pickupData = await getPickupByShipmentID(shipment.id);
            await cancelPickup(pickupData);
            await updatePickupStatus(pickupData.id, shipment.id, { status: "Cancelled" });
            await removePickupFromShipment(shipment.id);
        }
        await voidShipment(shipment);
        await createRefund(shipment.paymentSessionID);
        return true;
    } catch (e) {
        // console.error(e);
        throw e;
    }
};

export const handleCarrierError = (e: any, carrier: Carrier) => {
    try {
        if (carrier === "Purolator") {
            const errorResponse = e.response.data.ResponseInformation.Errors.Error[0];
            return errorResponse["Description"];
        }
        if (carrier === "UPS") {
            const errorResponse = e.response.data.response.errors[0];
            return errorResponse["message"];
        }
        if (carrier === "FedEx") {
            const errorResponse = e.response.data.errors[0];
            return errorResponse["message"];
        }
        if (carrier === "GLS") {
            return "Carrier Error: " + e.message;
        }
    } catch (error) {
        // console.error(error);
        return "Error: " + e.message;
    }
};
