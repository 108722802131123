import { ReactNode } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Description } from "@radix-ui/react-toast";

interface DetailSectionProps {
    title: string;
    details: { label: ReactNode; value: ReactNode }[];
    description?: string;
    "data-testid"?: string;
}

export default function DetailSection({ title, details, "data-testid": testId, description }: DetailSectionProps) {
    return (
        <div data-testid={testId}>
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
            {description && <p className="mb-4 text-sm text-gray-500">{description}</p>}
            <div className="space-y-2">
                {details.map((detail, index) => (
                    <>
                        <p className="flex justify-between" key={index}>
                            <span className="flex items-center space-x-1 text-gray-500">{detail.label}</span>
                            <span>{detail.value !== undefined && detail.value !== null && !Number.isNaN(detail.value) ? detail.value : <Skeleton className="h-4 w-12" />}</span>
                        </p>
                    </>
                ))}
            </div>
        </div>
    );
}
