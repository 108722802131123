import { ChevronLeft, ChevronRight, Circle, CircleCheck, CircleX, Home, Timer, CircleAlert, Tag, Truck, PackageCheck, MapPinHouse } from "lucide-react";
import { useEffect, useState } from "react";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { Card, CardTitle, CardContent, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/core/UserStore";
import { Customer, Shipment, ShipmentsTableHeader } from "@shared/ShipmentTypes";
import dayjs from "dayjs";
import { PickupLocation } from "@/components/composite/Cards/PickupDetails";
import { CreatePickupDialog, EditPickupDialog } from "../pickups/CreatePickupDialog";
import { PickupData, PickupStatus } from "@shared/PickupTypes";
import CarrierLogo from "@/components/composite/CarrierLogo";
import { useNavigate } from "react-router-dom";
import { cancelPickup } from "@/api/pickups/updatePickups";
import { toast } from "@/components/ui/use-toast";
import { statusStyleMapping } from "@/lib/statusMapping";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { collection, doc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "@/core/firebase";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { UpdateShipmentsStatusesResponse } from "@shared/ResponseTypes";
import { updateShipmentsStatuses } from "@/api/shipments/updateShipment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ShipmentsTable } from "../shipments/ShipmentsTable";
import { PickupsTable } from "../pickups/PickupsTable";
import { CustomAlertDialog } from "@/components/composite/CustomAlertDialog";
dayjs.extend(isSameOrAfter);

function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export type shipmentsOverviewType = {
    count: number;
    icon: string;
};

const shipmentsQueryClient = new QueryClient()
const pikcupsQueryClient = new QueryClient()
const startOfToday = dayjs().startOf("day").valueOf();

export default function HomeLayout() {
    const [shipments, setShipments] = useState<ShipmentsTableHeader[]>([]);
    const [pickups, setPickups] = useState<PickupData[]>([]);
    const [activePickups, setActivePickups] = useState<PickupData[]>([]);
    const [shipmentsOverview, setShipmentsOverview] = useState<Record<string, shipmentsOverviewType>>();
    const [openPickup, setOpenPickup] = useState(false);
    const [openEditPickup, setOpenEditPickup] = useState(false);
    const [shipmentsLoading, setShipmentsLoading] = useState(false)
    const [shipmentsPage, setShipmentsPage] = useState(0)
    const [shipmentsPageSize, setShipmentsPageSize] = useState(5)
    const [pickupsLoading, setPickupsLoading] = useState(false)
    const [pickupsPage, setPickupsPage] = useState(0)
    const [pickupsPageSize, setPickupsPageSize] = useState(5)
    const [currentPickup, setCurrentPickup] = useState<PickupData | null>(null);
    const user = useUserStore((state) => state.user);
    const navigate = useNavigate();

    const sortAndSetPickups = (pickups: PickupData[]) => {
        pickups.sort((a, b) => {
            const statusOrder = { "Scheduled": 1, "Cancelled": 2 };
            const statusA = a.status ? statusOrder[a.status] : 3;
            const statusB = b.status ? statusOrder[b.status] : 3;

            if (statusA !== statusB) {
                return statusA - statusB; // Sort by status order
            }

            const dateA = a.pickupDetails?.closeTimeStamp?.isoTimeStamp
                ? new Date(a.pickupDetails.closeTimeStamp.isoTimeStamp).getTime()
                : 0;
            const dateB = b.pickupDetails?.closeTimeStamp?.isoTimeStamp
                ? new Date(b.pickupDetails.closeTimeStamp.isoTimeStamp).getTime()
                : 0;

            return dateA - dateB; // Sort by date
        });

        setPickups(pickups)
    }

    const sortAndSetActivePickups = (activePickups: PickupData[]) => {
        activePickups = activePickups.filter(p => {
            const pickupTime = p.pickupDetails?.closeTimeStamp?.isoTimeStamp
                ? new Date(p.pickupDetails.closeTimeStamp.isoTimeStamp).getTime()
                : 0;
            return pickupTime >= startOfToday;
        });

        activePickups.sort((a, b) => {
            const statusOrder = { "Scheduled": 1, "Cancelled": 2 };
            const statusA = a.status ? statusOrder[a.status] : 3;
            const statusB = b.status ? statusOrder[b.status] : 3;

            if (statusA !== statusB) {
                return statusA - statusB; // Sort by status order
            }

            const dateA = a.pickupDetails?.closeTimeStamp?.isoTimeStamp
                ? new Date(a.pickupDetails.closeTimeStamp.isoTimeStamp).getTime()
                : 0;
            const dateB = b.pickupDetails?.closeTimeStamp?.isoTimeStamp
                ? new Date(b.pickupDetails.closeTimeStamp.isoTimeStamp).getTime()
                : 0;

            return dateA - dateB; // Sort by date
        });

        setActivePickups(activePickups)
    }

    // Mapping object for relevant icons only
    const iconMap = {
        CircleAlert,
        Tag,
        Truck,
        PackageCheck
    };
    const fetchShipments = async () => {
        if (!user || !user.email) {
            return;
        }

        setShipmentsLoading(true)
        const shipmentsQuery = query(collection(db, "shipments"), where("uid", "==", user.email), orderBy("date", "desc"));

        const shipmentsSnapshot = await getDocs(shipmentsQuery);


        const shipments: ShipmentsTableHeader[] = []
        const shipmentsOverview: Record<string, shipmentsOverviewType> = {
            "Delay / Warning": { count: 0, icon: "CircleAlert" },
            "Label Created": { count: 0, icon: "Tag" },
            "In-Transit": { count: 0, icon: "Truck" },
            Delivered: { count: 0, icon: "PackageCheck" }
        };

        shipmentsSnapshot.forEach((doc) => {
            const data = doc.data() as Shipment;
            const status = data.status

            if (status === "Label Created") {
                shipmentsOverview[status].count++;
            } else if (status === "In-Transit") {
                shipmentsOverview[status].count++;
            } else if (status === "Delivered") {
                shipmentsOverview[status].count++;
            } else if (status === "Delay / Warning") {
                shipmentsOverview[status].count++;
            }

            shipments.push({
                id: doc.id,
                carrier: data.serviceDetails?.carrier || "N/A",
                customerName: data.receiver[0].attentionName,
                status: data.status || "Label Created",
                trackingNumber: data.trackingNumber || "N/A",
                date: data.date || "N/A"
            });
        });

        setShipments(shipments);
        setShipmentsOverview(shipmentsOverview);
        setShipmentsLoading(false)
        updateShipmentStatuses(shipments, shipmentsPage, shipmentsPageSize);
    }

    const fetchPickups = async () => {
        if (!user || !user.email) {
            return;
        }

        setPickupsLoading(true)
        const pickupsQuery = query(collection(db, "pickups"), where("uid", "==", user.email));

        const pickupsSnapshot = await getDocs(pickupsQuery);

        const pickups: PickupData[] = []
        const activePickups: PickupData[] = []

        pickupsSnapshot.forEach((doc) => {
            const data = doc.data() as PickupData

            pickups.push({
                ...data,
                // TODO: using capitalizeFirstLetter because was storing lowercase "cancelled", update db and remove this line
                status: data.status ? capitalizeFirstLetter(data.status) as PickupStatus : "Scheduled",
            });

            if (dayjs(data.pickupDetails.closeTimeStamp!.isoTimeStamp).isSameOrAfter(dayjs()) && data.status !== "Cancelled") {
                activePickups.push(data);
            }
        });

        // TODO: pull pickups in already sorted order with an index (on closeTimestamp?)
        // TODO: why doesn't the data table sorting work?
        sortAndSetPickups(pickups);
        sortAndSetActivePickups(activePickups);
        setPickupsLoading(false);
    }

    useEffect(() => {
        const fetchData = async () => {

            fetchShipments()
            fetchPickups()

        }
        fetchData();
    }, [user]);

    async function updateShipmentStatuses(shipments: ShipmentsTableHeader[], _page: number, _pageSize: number) {
        const shipmentIds: string[] = []
        const startInclusive = _page * _pageSize
        const endExclusive = (_page + 1) * _pageSize

        // TODO: only send / show loading for shipments which aren't Delivered or Cancelled
        for (let i = startInclusive; i < endExclusive && i < shipments.length; i++) {
            shipmentIds.push(shipments[i].id)
            shipments[i].statusLoading = true
        }

        setShipments([...shipments]); // new reference required to force a rerender (not sure if this one is necessary but just in case adding)

        const updateShipmentStatusesResponse: UpdateShipmentsStatusesResponse = await updateShipmentsStatuses(shipmentIds);

        if (updateShipmentStatusesResponse.httpStatusCode === 500 || Object.values(updateShipmentStatusesResponse.data).length === 0) {
            for (let i = startInclusive; i < endExclusive && i < shipments.length; i++) {
                shipments[i].statusLoading = false
                shipments[i].hasError = true
            }
        } else {
            for (let i = startInclusive; i < endExclusive && i < shipments.length; i++) {
                const result = updateShipmentStatusesResponse.data[shipments[i].id]

                if (!result) {
                    shipments[i].status = shipments[i].status
                    shipments[i].hasError = true
                } else {
                    shipments[i].status = result.status
                    shipments[i].hasError = result.hasError
                }
                shipments[i].statusLoading = false
            }
        }


        setShipments([...shipments]); // new reference required to force a rerender
    }

    const _setShipmentsPage = (_shipmentsPage: number) => {
        updateShipmentStatuses(shipments, _shipmentsPage, shipmentsPageSize);
        setShipmentsPage(_shipmentsPage);
    };

    const _setShipmentsPageSize = (_shipmentsPageSize: number) => {
        updateShipmentStatuses(shipments, shipmentsPage, _shipmentsPageSize);
        setShipmentsPageSize(_shipmentsPageSize);
    };

    const _setPickupsPage = (_pickupsPage: number) => {
        // updatePickupstatuses(pickups, _pickupsPage, pickupsPageSize);
        setPickupsPage(_pickupsPage);
    };

    const _setPickupsPageSize = (_pickupsPageSize: number) => {
        // updatePickupstatuses(pickups, pickupsPage, _pickupsPageSize);
        setPickupsPageSize(_pickupsPageSize);
    };

    const pickupRowNavigate = () => {
        // do nothing
    }

    const handleOpenEditPickup = (pickup: PickupData) => {
        setCurrentPickup(pickup)
        setOpenEditPickup(true)
    }

    const handleCreatePickup = (pickup: PickupData) => {
        pickup.status = "Scheduled"
        sortAndSetPickups([pickup, ...pickups])
        sortAndSetActivePickups([pickup, ...activePickups])
    }

    // Edit Dialog returns null if something went wrong (this means the original pickup was removed - TODO: change the flow here so it doesn't remove the original)
    const handleEditPickup = (pickup: PickupData) => {
        if (!pickup && currentPickup) {
            sortAndSetPickups(pickups.filter(p => p.id !== currentPickup.id))
            sortAndSetActivePickups(activePickups.filter(p => p.id !== currentPickup.id))
        } else if (pickup) {
            sortAndSetPickups(pickups.map(p => {
                if (p.id === pickup.id) {
                    return pickup
                } else {
                    return p
                }
            }))
            sortAndSetActivePickups(activePickups.map(p => {
                if (p.id === pickup.id) {
                    return pickup
                } else {
                    return p
                }
            }))
        }
    }

    const handleCancelPickup = async (pickup: PickupData) => {
        try {
            pickup.status = "Cancelled"
            const response = await cancelPickup(pickup)
            await setDoc(doc(db, "pickups", pickup.id!), { status: "Cancelled" }, { merge: true });
            toast({ title: "Pickup Cancelled", description: "Your pickup was cancelled" });

            setPickups(pickups.map(p => p.id === pickup.id ? pickup : p))
            const filteredActivePickups = activePickups.filter(p => p.id !== pickup.id)
            setActivePickups(activePickups.filter(p => p.id !== pickup.id))
        } catch (e) {
            console.error(e);
            toast({ title: "Pickup not Cancelled", description: "Please try again", variant: "destructive" });
        };
    }

    return (
        <>
            <PageTitle>
                <Home />
                Home
            </PageTitle>
            <div className="md:flex gap-2">
                <div className="flex-1 space-y-4">
                    {/* <OrderStatistics shipment={shipments} /> */}
                    <div className="md:grid grid-cols-2 gap-4">
                        <ShipmentStatistics shipmentStats={shipmentsOverview} iconMap={iconMap} />
                        <ActivePickups activePickups={activePickups} setOpenPickup={setOpenPickup} handleOpenEditPickup={handleOpenEditPickup} handleCancelPickup={handleCancelPickup} />
                    </div>
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-xl">Recent Shipments</CardTitle>
                        </CardHeader>
                        <CardContent className="overflow-x-auto">
                            <QueryClientProvider client={shipmentsQueryClient}>
                                <ShipmentsTable pageSize={5} />
                            </QueryClientProvider>
                            {shipments.length !== 0 && (
                                <Button variant="link" className="my-0 px-0" onClick={() => navigate("shipments")}>
                                    View all shipments
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-xl">Pickups</CardTitle>
                        </CardHeader>
                        <CardContent className="overflow-x-auto">
                            <QueryClientProvider client={pikcupsQueryClient} >
                                <PickupsTable pickups={pickups} handleOpenEditPickup={handleOpenEditPickup} handleCancelPickup={handleCancelPickup} pageSize={5} />
                            </QueryClientProvider>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <CreatePickupDialog open={openPickup} setOpen={setOpenPickup} handleCreatePickup={handleCreatePickup} />
            {currentPickup && <EditPickupDialog open={openEditPickup} setOpen={setOpenEditPickup} handleEditPickup={handleEditPickup} pickup={currentPickup} />}
        </>
    );
}

const ShipmentStatistics = ({ shipmentStats, iconMap }) => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-2 gap-4 mb-4 md:mb-0">
            {shipmentStats && Object.keys(shipmentStats).length > 0 && ["Label Created", "In-Transit", "Delay / Warning", "Delivered"].map((key) => {
                const value = shipmentStats[key]
                const { count, icon } = value as shipmentsOverviewType;
                const IconComponent = iconMap[icon];

                return (
                    <Card className="flex flex-col items-center justify-center p-6 text-center " key={key}>
                        <CardHeader>
                            <CardTitle className={"flex flex-col justify-center items-center gap-2 text-2xl " + statusStyleMapping[key]}>
                                <IconComponent className="h-14 w-14 flex-shrink-0" />
                                {key === "Delay / Warning" ? "May need your attention" : key}
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="flex justify-center">
                            <CardTitle className="text-2xl font-bold">
                                {count > 0 ? (
                                    <span
                                        className="cursor-pointer hover:underline"
                                        onClick={() =>
                                            navigate("shipments?status=" + (key === "Attention" ? "Delay / Warning " : key))
                                        }
                                    >
                                        {Number(count)}
                                    </span>
                                ) : (
                                    <span>{Number(count)}</span>
                                )}
                            </CardTitle>
                        </CardContent>
                    </Card>
                );
            })}
        </div>
    );
};

const OrderStatistics = ({ shipment }) => {
    return (
        <div className={"flex w-full gap-4 "}>
            <Card className="flex-1">
                <CardHeader>
                    <CardTitle className="flex items-center gap-1.5 text-sm">
                        <Circle className="h-4 w-4" />
                        Unfulfilled
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <CardTitle>{shipment.length}</CardTitle>
                </CardContent>
            </Card>
            <Card className="flex-1">
                <CardHeader>
                    <CardTitle className="flex items-center gap-1.5 text-sm">
                        <CircleCheck className="h-4 w-4" />
                        Fulfilled
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <CardTitle>{shipment.length}</CardTitle>
                </CardContent>
            </Card>
            <Card className="flex-1">
                <CardHeader>
                    <CardTitle className="flex items-center gap-1.5 text-sm">
                        <Timer className="h-4 w-4" />
                        Overdue
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <CardTitle>{shipment.length}</CardTitle>
                </CardContent>
            </Card>
            <Card className="flex-1">
                <CardHeader>
                    <CardTitle className="flex items-center gap-1.5 text-sm">
                        <CircleX className="h-4 w-4" />
                        Cancelled
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <CardTitle>{shipment.length}</CardTitle>
                </CardContent>
            </Card>
        </div>
    );
};

interface ActivePickupsProps {
    activePickups: PickupData[];
    setOpenPickup: React.Dispatch<React.SetStateAction<boolean>>;
    handleOpenEditPickup: Function;
    handleCancelPickup: Function;
}

const ActivePickups = ({ activePickups, setOpenPickup, handleOpenEditPickup, handleCancelPickup }: ActivePickupsProps) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [CancelPickupBtnState, setCancelPickupBtnState] = useState(false);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, activePickups.length - 1));
    };

    const handleCancel = async () => {
        activePickups[currentPage].status = "Cancelled"
        handleCancelPickup(activePickups[currentPage])
    };

    const handleEdit = async () => {
        handleOpenEditPickup(activePickups[currentPage])
    };

    // required if cancelling a pickup from this component that is not the first pickup
    useEffect(() => {
        if (currentPage > activePickups.length - 1) {
            setCurrentPage(Math.max(activePickups.length - 1, 0))
        }
    }, [activePickups])

    return (
        <Card className="flex flex-col flex-1 h-full min-h-[300px]">
            <CardHeader>
                <CardTitle className="flex justify-between">
                    <p className="text-xl">Upcoming Pickups</p>
                    <Button onClick={() => setOpenPickup(true)} className="w-fit" variant="secondary">
                        <MapPinHouse className="mr-2 h-4 w-4" /> Create a pickup
                    </Button>
                </CardTitle>
            </CardHeader>

            {activePickups.length > 0 ? (
                <CardContent className="flex flex-col flex-grow justify-between">
                    <div className="grid grid-cols-2 gap-2.5 flex-grow">
                        <div className="rounded-md bg-gray-100 p-6 flex flex-col h-full">
                            <CarrierLogo carrier={activePickups[currentPage].carrier} className={`self-start object-contain py-1 ${activePickups[currentPage].carrier === "UPS" ? "h-8" : "h-6"}`} />
                            <p className="font-semibold mt-2">{activePickups[currentPage].id}</p>
                            <p className="">{dayjs(activePickups[currentPage].pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from {dayjs(activePickups[currentPage].pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to {dayjs(activePickups[currentPage].pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}</p>
                            <p className="font-semibold mt-2">Carrier Confirmation No.</p>
                            <p className="">{activePickups[currentPage].data}</p>

                            {/* Fix: Cancel button now sticks to the bottom */}
                            <div className="mt-auto">
                                <CustomAlertDialog title="Are you sure?" description="This action cannot be undone. This will permanently cancel this pickup." onConfirm={handleCancel}>
                                    <LoadingButton isLoading={CancelPickupBtnState} variant="destructive" className="w-full">
                                        Cancel
                                    </LoadingButton>
                                </CustomAlertDialog>
                            </div>
                        </div>
                        <div className="p-4 flex-1">
                            <p className="mt-2 font-bold">Address</p>
                            {activePickups?.[currentPage]?.shipper && (
                                <div>
                                    <p>{activePickups[currentPage]!.shipper!.address!.street!}</p>
                                    <p>{activePickups[currentPage]!.shipper!.address!.city!}, {activePickups[currentPage]!.shipper!.address!.stateCode!} {activePickups[currentPage]!.shipper!.address!.postalCode!}</p>
                                </div>
                            )}
                            <p className="mt-2 font-bold">Pickup Location</p>
                            <p className="">{activePickups?.[currentPage]?.pickupDetails?.pickupLocation ? PickupLocation[activePickups[currentPage].pickupDetails.pickupLocation!] : "None"}</p>
                            <p className="mt-2 font-bold">Instructions for the driver:</p>
                            <p className="">{activePickups[currentPage].pickupDetails.specialNote || "None"}</p>
                        </div>
                    </div>

                    {/* Fix: Added space between pickup content and pagination */}
                    <div className="col-span-2 flex rounded-md bg-gray-100 p-5 mt-4">
                        <p className="text-sm">{currentPage + 1} of {activePickups.length}</p>
                        <div className="ml-auto">
                            <Button size="icon" variant="outline" className="h-6 w-6" onClick={handlePreviousPage} disabled={currentPage === 0}>
                                <ChevronLeft className="h-3.5 w-3.5" />
                            </Button>
                            <Button size="icon" variant="outline" className="h-6 w-6" onClick={handleNextPage} disabled={currentPage === activePickups.length - 1}>
                                <ChevronRight className="h-3.5 w-3.5" />
                            </Button>
                        </div>
                    </div>
                </CardContent>
            ) : (
                <CardContent className="flex h-full items-center justify-center m-auto">
                    <p className="text-lg">No upcoming pickups.</p>
                </CardContent>
            )}
        </Card>
    );
};
