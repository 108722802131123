import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Trucks from "@/assets/misc/Trucks.svg";
import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { privacySections } from "./privacySections";

export default function PrivacyPolicyPage() {
    return (
        <>
          <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:max-h-[200px]" data-testid="getquote">
            <div className="relative flex flex-col">
              {/* Header with logo and login button */}
              <div className="flex justify-between p-6 z-10">
                <img src={logo || "/placeholder.svg"} className="h-10 place-self-center object-cover" alt="Logo" />
                <Link to="/login">
                  <Button variant="secondary">Login</Button>
                </Link>
              </div>
    
              {/* Privacy policy card container with proper spacing */}
              <div className="container mx-auto py-4 px-4 flex-1">
                <Card className="max-w-4xl mx-auto" style={{ maxHeight: "85vh" }}>
                  <CardHeader>
                    <CardTitle className="text-3xl font-bold text-center">{privacySections.introduction.title}</CardTitle>
                    <p className="text-center text-muted-foreground">
                      Last updated {privacySections.introduction.lastUpdated}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <ScrollArea className="pr-4" style={{ height: `calc(83vh - 116px)` }}>
                      <div className="space-y-6">
                        <section className="prose dark:prose-invert max-w-none">
                          {/* Introduction */}
                          <p>{privacySections.introduction.content}</p>
                          <ul className="list-disc ml-5">
                            {privacySections.introduction.bullets.map((bullet, index) => (
                              <li key={index}>{bullet}</li>
                            ))}
                          </ul>
                          <p>{privacySections.introduction.contact}</p>
    
                          {/* Key Points */}
                          <h2 className="text-2xl font-bold mt-8">{privacySections.keyPoints.title}</h2>
                          {privacySections.keyPoints.points.map((point, index) => (
                            <div key={index} className="mt-4">
                              <h3 className="text-xl font-semibold">{point.title}</h3>
                              <p>{point.content}</p>
                            </div>
                          ))}
    
                          {/* Information Collection */}
                          <h2 className="text-2xl font-bold mt-8">{privacySections.informationCollection.title}</h2>
                          {privacySections.informationCollection.sections.map((section, index) => (
                            <div key={index} className="mt-6">
                              <h3 className="text-xl font-semibold">{section.subtitle}</h3>
                              <p className="italic">{section.shortDescription}</p>
                              <p>{section.content}</p>
    
                              <div className="mt-4">
                                <h4 className="text-lg font-semibold">{section.personalInfo.title}</h4>
                                <p>{section.personalInfo.content}</p>
                                <ul className="list-disc ml-5">
                                  {section.personalInfo.items.map((item, idx) => (
                                    <li key={idx}>{item}</li>
                                  ))}
                                </ul>
                              </div>
    
                              <div className="mt-4">
                                <h4 className="text-lg font-semibold">{section.sensitiveInfo.title}</h4>
                                <p>{section.sensitiveInfo.content}</p>
                              </div>
    
                              <div className="mt-4">
                                <h4 className="text-lg font-semibold">{section.paymentInfo.title}</h4>
                                <p>{section.paymentInfo.content}</p>
                              </div>
                            </div>
                          ))}
    
                          {/* Information Processing */}
                          <h2 className="text-2xl font-bold mt-8">{privacySections.informationProcessing.title}</h2>
                          <p className="italic">{privacySections.informationProcessing.shortDescription}</p>
                          <ul className="list-disc ml-5">
                            {privacySections.informationProcessing.purposes.map((purpose, index) => (
                              <li key={index}>{purpose}</li>
                            ))}
                          </ul>
    
                          {/* Legal Bases */}
                          <h2 className="text-2xl font-bold mt-8">{privacySections.legalBases.title}</h2>
                          <p className="italic">{privacySections.legalBases.shortDescription}</p>
                          <ul className="list-disc ml-5">
                            {privacySections.legalBases.exceptions.map((exception, index) => (
                              <li key={index}>{exception}</li>
                            ))}
                          </ul>
    
                          {/* Additional sections would continue here... */}
    
                          <h2 className="text-2xl font-bold mt-8">
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                          </h2>
                          <p className="italic">
                            In Short: We may share information in specific situations and with specific third parties.
                          </p>
                          <p>
                            We may share or transfer your information in connection with, or during negotiations of, any
                            merger, sale of company assets, financing, or acquisition of all or a portion of our business to
                            another company.
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                          <p className="italic">
                            In Short: We keep your information for as long as necessary to fulfil the purposes outlined in
                            this Privacy Notice unless otherwise required by law.
                          </p>
                          <p>
                            We will only keep your personal information for as long as it is necessary for the purposes set
                            out in this Privacy Notice, unless a longer retention period is required or permitted by law
                            (such as tax, accounting, or other legal requirements).
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                          <p className="italic">
                            In Short: We aim to protect your personal information through a system of organizational and
                            technical security measures.
                          </p>
                          <p>
                            We have implemented appropriate and reasonable technical and organizational security measures
                            designed to protect the security of any personal information we process. However, despite our
                            safeguards and efforts to secure your information, no electronic transmission over the Internet
                            or information storage technology can be guaranteed to be 100% secure.
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                          <p className="italic">
                            In Short: We do not knowingly collect data from or market to children under 18 years of age.
                          </p>
                          <p>
                            We do not knowingly collect, solicit data from, or market to children under 18 years of age. By
                            using the Services, you represent that you are at least 18 or that you are the parent or
                            guardian of such a minor and consent to such minor dependent's use of the Services.
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                          <p className="italic">
                            In Short: In some regions, such as Canada, you have rights that allow you greater access to and
                            control over your personal information.
                          </p>
                          <p>
                            You may review, change, or terminate your account at any time. If you have questions or comments
                            about your privacy rights, you may email us at info@snapship.co.
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                          <p>
                            Most web browsers and some mobile operating systems and mobile applications include a
                            Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not
                            to have data about your online browsing activities monitored and collected.
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                          <p className="italic">
                            In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                          </p>
                          <p>
                            We may update this Privacy Notice from time to time. The updated version will be indicated by an
                            updated 'Revised' date at the top of this Privacy Notice.
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                          <p>
                            If you have questions or comments about this notice, you may email us at{" "}
                            <a href="mailto:info@snapship.co" className="text-primary hover:underline">
                              info@snapship.co
                            </a>
                          </p>
    
                          <h2 className="text-2xl font-bold mt-8">
                            12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                          </h2>
                          <p>
                            Based on the applicable laws of your country, you may have the right to request access to the
                            personal information we collect from you, change that information, or delete it. To request to
                            review, update, or delete your personal information, please contact us.
                          </p>
                        </section>
                      </div>
                    </ScrollArea>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="hidden lg:block" style={{ backgroundColor: "#FCFCFC" }}>
              <img src={Trucks || "/placeholder.svg"} alt="Trucks" className="max-h-screen w-full object-cover" />
            </div>
          </div>
        </>
      )
}