import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { ArrowDownToLine, ArrowUpToLine, CircleX, ExternalLink, Import, Info, X } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import Select from "@/components/composite/Inputs/Select";
import InputList from "@/components/composite/Inputs/InputList";
import { createContext, memo, useContext, useEffect, useState } from "react";
import Input from "@/components/composite/Inputs/Input";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormTitle from "@/components/composite/Headers/FormTitle";
import { useFormStore } from "@/core/ShipmentFormStore";

import Checkbox from "@/components/composite/Inputs/Checkbox";

import ShipmentOptions, { DeclaredCurrencyList, DeclaredOptionSelect, DeclaredValueInput, DeclaredValueTooltip } from "./ShipmentOptions";
import { Button } from "@/components/ui/button";
import { PackageDimensions, ShipmentDetails } from "@shared/ShipmentTypes";
import { savePackage, getPackages } from "@/api/packages";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useUserStore } from "@/core/UserStore";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/core/firebase";
import { DataTable } from "./saved-packages/data-table";
import { columns, PackageTableHeader } from "./saved-packages/columns";
import { useToast } from "@/components/ui/use-toast";
import { IndexContext } from "@/context/SelectedIndex";
import { PackageDialogContext, usePackageDialogContext } from "@/context/PackageDialog";

const DeliveryFormat = [
    {
        name: "carrier",
        label: "Carrier Packaging"
    },
    {
        name: "package",
        label: "My Packaging"
    }
];

const declaredValueContext = createContext(false);

const PackageDetails = memo(function PackageDetails() {
    const { getValues, watch } = useFormContext();

    const packageLength = getValues("packages");

    const handleChange = (value: "package" | "carrier") => {
        setDeliveryFormatValue(value);
    };
    const [deliveryFormatValue, setDeliveryFormatValue] = useState<"package" | "carrier">(useFormStore.getState().shipmentDetails.deliveryFormat || getValues("deliveryFormat") || "carrier");

    const defaultValue = () => {
        if (deliveryFormatValue === "carrier" || deliveryFormatValue === "package") {
            return deliveryFormatValue;
        }
        return "carrier";
    };

    const array = Array(packageLength?.length ?? 1).fill(DimensionsInputList);

    const [dimensionsInputs, setDimensionsInputs] = useState<(({ index, dimensionsInputs, setDimensionsInputs, deliveryFormatValue }: DimensionsInputList) => JSX.Element)[]>(array);

    const handleAdd = () => {
        setDimensionsInputs([...dimensionsInputs, DimensionsInputList]);
    };

    useEffect(() => {
        deliveryFormatValue === "carrier" && setDimensionsInputs([DimensionsInputList]);
    }, [deliveryFormatValue]);

    const initialDeclaredValue = getValues("packages")
        ? (getValues("packages") as PackageDimensions[]).reduce((sum, box) => {
              if (box?.declaredValue) {
                  return sum + box.declaredValue;
              } else {
                  return sum;
              }
          }, 0)
        : 0;
    const [totalDeclaredValue, setTotalDeclaredValue] = useState<number>(initialDeclaredValue);

    useEffect(() => {
        const { unsubscribe } = watch((value) => {
            const packages: PackageDimensions[] = value.packages;

            if (packages) {
                const total = packages.reduce((sum, box) => {
                    if (box?.declaredValue) {
                        return sum + box.declaredValue;
                    } else {
                        return sum;
                    }
                }, 0);

                setTotalDeclaredValue(total);
            }
        });

        return () => unsubscribe();
    }, [watch]);

    const [declaredValueToggle, setDeclaredValueToggle] = useState<boolean>(getValues("declaredValueToggle") ?? false);
    const toggleDeclaredValue = (value: boolean) => {
        setDeclaredValueToggle(value);
    };

    return (
        <Card>
            <CardHeader>
                <FormTitle>Package Details</FormTitle>
            </CardHeader>
            <CardContent className="grid gap-6">
                <div className="grid grid-cols-3 items-center gap-6">
                    <Select name="deliveryFormat" SelectItemList={DeliveryFormat} defaultValue={defaultValue()} onChange={handleChange} />
                    <ShipmentOptions />
                    <div className="div col-span-3 ml-6">
                        <Checkbox name={`declaredValueToggle`} label="Do you want to purchase additional insurance?" onChange={toggleDeclaredValue} defaultValue={getValues("declaredValueToggle")} />
                    </div>
                </div>
                <declaredValueContext.Provider value={declaredValueToggle}>
                    {dimensionsInputs.map((DimInput, index) => {
                        return <DimInput key={index} index={index} dimensionsInputs={dimensionsInputs} setDimensionsInputs={setDimensionsInputs} deliveryFormatValue={deliveryFormatValue} />;
                    })}
                    {deliveryFormatValue === "package" && (
                        <Button type="button" onClick={handleAdd} variant={"secondary"}>
                            Add Package
                        </Button>
                    )}
                    {deliveryFormatValue === "package" && dimensionsInputs.length !== 1 && declaredValueToggle && (
                        <div className="grid grid-cols-[3fr_1fr] gap-2">
                            <Input name="totalDeclaredValue" value={"$" + totalDeclaredValue} label="Total Cost Value of the Goods Being Shipped" disabled={true} />
                            <DeclaredOptionSelect />
                        </div>
                    )}
                </declaredValueContext.Provider>
            </CardContent>
        </Card>
    );
});

interface DimensionsInputList {
    index: number;
    dimensionsInputs: (({ index, dimensionsInputs, setDimensionsInputs }: { index: any; dimensionsInputs: any; setDimensionsInputs: any }) => JSX.Element)[];
    setDimensionsInputs: React.Dispatch<React.SetStateAction<(({ index, dimensionsInputs, setDimensionsInputs }: { index: any; dimensionsInputs: any; setDimensionsInputs: any }) => JSX.Element)[]>>;
    deliveryFormatValue: string;
}

const DimensionsInputList = ({ index, dimensionsInputs, setDimensionsInputs, deliveryFormatValue }: DimensionsInputList) => {
    const declaredValueToggle = useContext(declaredValueContext);

    const { setValue, getValues, watch } = useFormContext();

    const { remove } = useFieldArray({ name: "packages" });

    const toggleBatteries = (value: boolean) => {
        setValue(`packages.${index}.batteries`, value);
    };

    const handleRemove = () => {
        const newDimensionsInputs = dimensionsInputs.filter((_, i) => i !== index);

        // Update the indices of the remaining packages
        newDimensionsInputs.forEach((_, newIndex) => {
            if (newIndex >= index) {
                // Update the form values to shift the remaining inputs ahead
                setValue(`packages.${newIndex}.weight`, getValues(`packages.${newIndex + 1}.weight`));
                setValue(`packages.${newIndex}.length`, getValues(`packages.${newIndex + 1}.length`));
                setValue(`packages.${newIndex}.width`, getValues(`packages.${newIndex + 1}.width`));
                setValue(`packages.${newIndex}.height`, getValues(`packages.${newIndex + 1}.height`));
                setValue(`packages.${newIndex}.batteries`, getValues(`packages.${newIndex + 1}.batteries`));
                setValue(`packages.${newIndex}.description`, getValues(`packages.${newIndex + 1}.description`));
                setValue(`packages.${newIndex}.declaredValue`, getValues(`packages.${newIndex + 1}.declaredValue`));
            }
        });

        // Remove the last package values (since it has been shifted)
        setValue(`packages.${newDimensionsInputs.length}`, undefined);
        remove(newDimensionsInputs.length);

        // Update the state
        setDimensionsInputs(newDimensionsInputs);
    };

    const { toast } = useToast();

    const handleSave = () => {
        const packages: PackageDimensions = getValues(`packages.${index}`);
        if (packages.weight) {
            savePackage(packages);
            toast({ description: "Package saved", className: "bg-success-400" });
        } else {
            toast({ title: "Package not saved", description: "Missing package dimension", variant: "destructive" });
        }
    };

    const [open, setOpen] = useState(false);

    const handleSameAsAbove = () => {
        setValue(`packages.${index}.weight`, getValues(`packages.${index - 1}.weight`));
        setValue(`packages.${index}.length`, getValues(`packages.${index - 1}.length`));
        setValue(`packages.${index}.width`, getValues(`packages.${index - 1}.width`));
        setValue(`packages.${index}.height`, getValues(`packages.${index - 1}.height`));
        setValue(`packages.${index}.batteries`, getValues(`packages.${index - 1}.batteries`));
        setValue(`packages.${index}.description`, getValues(`packages.${index - 1}.description`));
        setValue(`packages.${index}.declaredValue`, getValues(`packages.${index - 1}.declaredValue`));
    };

    const [units, setUnits] = useState<ShipmentDetails["units"]>(getValues("units") ?? "imperial");

    useEffect(() => {
        const { unsubscribe } = watch((value) => {
            const unitsValue: ShipmentDetails["units"] = value.units;
            setUnits(unitsValue);
        });

        return () => unsubscribe();
    }, [watch]);
    return (
        <IndexContext.Provider value={index}>
            <Card>
                <CardHeader className="flex-row items-center justify-between">
                    <div className="w-fit">Package {index + 1}</div>
                    <div className="m-0 !mt-0 flex gap-8 place-self-end p-0">
                        {dimensionsInputs.length > 1 && (
                            <>
                                {index > 0 && (
                                    <Button variant={"link"} type="button" onClick={handleSameAsAbove} className="m-0 flex h-fit w-fit gap-1 p-0 text-blue-500">
                                        <Import className="mr-2 h-4 w-4" />
                                        Same as above
                                    </Button>
                                )}
                                <Button variant={"link"} type="button" onClick={handleRemove} className="text-error-500  hover:text-error-600 m-0 flex h-fit w-fit gap-1 p-0">
                                    <CircleX className="mr-2 h-4 w-4" />
                                    Remove Package
                                </Button>
                            </>
                        )}
                    </div>
                </CardHeader>
                <CardContent className="grid gap-4">
                    <div className="grid gap-6">
                        <div className={"grid grid-cols-4 gap-6"}>
                            <Input label={units === "imperial" ? "Weight (lb)" : "Weight (kg)"} name={`packages.${index}.weight`} type="number" min={0} />
                            <Input label={units === "imperial" ? "Length (in)" : "Length (cm)"} name={`packages.${index}.length`} type="number" min={0} />
                            <Input label={units === "imperial" ? "Width (in)" : "Width (cm)"} name={`packages.${index}.width`} type="number" min={0} />
                            <Input label={units === "imperial" ? "Height (in)" : "Height (cm)"} name={`packages.${index}.height`} type="number" min={0} />
                            {/* <Input label={"Description"} name={`packages.${index}.description`} className="col-span-2" /> */}
                        </div>
                        <Checkbox name={`packages.${index}.batteries`} label="My package contains batteries" onChange={toggleBatteries} />

                        {deliveryFormatValue === "package" && dimensionsInputs.length === 1 && declaredValueToggle && (
                            <div className="grid grid-cols-[2.5fr_1fr] gap-6">
                                <DeclaredValueInput index={index} totalLabel={true} />
                                <DeclaredOptionSelect />
                            </div>
                        )}

                        {deliveryFormatValue === "package" && dimensionsInputs.length !== 1 && declaredValueToggle && (
                            <div className="grid grid-cols-[2.5fr_1fr] gap-6">
                                <Input label={"Description"} name={`packages.${index}.description`} />
                                <DeclaredValueInput index={index} />
                            </div>
                        )}

                        {deliveryFormatValue === "carrier" && declaredValueToggle && (
                            <div className="grid grid-cols-[2.5fr_1fr] gap-6">
                                {/* <div className="grid grid-cols-[3fr_1.5fr_1fr] gap-6"> */}
                                {/* <Input label={"Description"} name={`packages.${index}.description`} /> */}
                                <DeclaredValueInput index={index} totalLabel={true} />
                                <DeclaredOptionSelect />
                            </div>
                        )}
                    </div>
                    {deliveryFormatValue === "package" && (
                        <div className="flex place-content-end gap-2">
                            {/* {dimensionsInputs.length > 1 && index !== 0 && (
                                <>
                                    <Button variant={"link"} type="button" onClick={handleSameAsAbove} className="flex w-fit gap-1 text-blue-500">
                                        <Import className="mr-2 h-4 w-4" />
                                        Same as above
                                    </Button>
                                </>
                            )} */}
                            <Button variant={"link"} type="button" onClick={() => setOpen(!open)} className="text">
                                <ExternalLink className="mr-2 h-4 w-4" />
                                Open Saved Packaging Dimensions
                            </Button>
                            <Button variant={"link"} type="button" onClick={handleSave} className="text text-success-600 w-fit">
                                <ArrowDownToLine className="mr-2 h-4 w-4" />
                                Save Package
                            </Button>
                        </div>
                    )}
                </CardContent>
                <PackageDialogContext.Provider value={{ open: open, setOpen: setOpen }}>
                    <PackageList />
                </PackageDialogContext.Provider>
            </Card>
        </IndexContext.Provider>
    );
};

interface PackagesList {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    index?: number;
}
const PackageList = () => {
    const { open, setOpen } = usePackageDialogContext();

    const uid = useUserStore.getState().user?.email;

    const [data, setData] = useState<PackageTableHeader[]>([]);

    useEffect(() => {
        if (uid) {
            const unsub = onSnapshot(doc(db, "packages", uid), (doc) => {
                const docData = doc.data();
                console.log("Current data: ", docData);
                if (docData) {
                    const packagesList = docData.packages as PackageDimensions[];

                    setData(
                        packagesList.map((value, index) => {
                            return {
                                description: value.description,
                                weight: value.weight,
                                length: value.length,
                                height: value.height,
                                width: value.width
                            } as PackageTableHeader;
                        })
                    );
                }
            });
            return () => {
                unsub();
                console.log("unsubbed");
            };
        }
    }, []);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Saved Packages</DialogTitle>
                    <DialogDescription>Save, Edit and Remove packages here</DialogDescription>
                </DialogHeader>
                <DataTable data={data} columns={columns} />
            </DialogContent>
        </Dialog>
    );
};

export default PackageDetails;
