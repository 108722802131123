import { ReactNode } from "react";
import { capitalizeFirstLetter } from "@/lib/utils";

import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

interface PackageDimensions {
    height: number;
    weight: number;
    length: number;
    width: number;
}

interface DetailSectionProps {
    title: string;
    description: string;
    packages: PackageDimensions[];
    units: string | "imperial" | "metric";
}

export default function PackageDetailsSection({ title, description, packages, units }: DetailSectionProps) {
    return (
        <>
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
            <div className="space-y-2">
                <p className="flex justify-between">
                    <span className="flex items-center space-x-1 text-gray-500">Type</span>
                    <span>Package</span>
                </p>
                <p className="flex justify-between">
                    <span className="flex items-center space-x-1 text-gray-500">Description</span>
                    <span>{description}</span>
                </p>

                {packages.length === 1 ? (
                    <div className="border-t pt-2">
                        <div className="font-medium">Package 1</div>
                        {Object.entries({
                            length: packages[0].length,
                            width: packages[0].width,
                            height: packages[0].height,
                            weight: packages[0].weight
                        }).map(([dimension, value]) => (
                            <p className="flex justify-between" key={dimension}>
                                <span className="flex items-center space-x-1 text-gray-500">{capitalizeFirstLetter(dimension)}</span>
                                <span>
                                    {value} {units === "imperial" ? (dimension === "weight" ? "lb" : "in") : dimension === "weight" ? "kg" : "cm"}
                                </span>
                            </p>
                        ))}
                    </div>
                ) : (
                    <div className="pt-2">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Number</TableHead>
                                    <TableHead>Length</TableHead>
                                    <TableHead>Width</TableHead>
                                    <TableHead>Height</TableHead>
                                    <TableHead>Weight</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {packages.map((pkg, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            {pkg.length} {units === "imperial" ? "in" : "cm"}
                                        </TableCell>
                                        <TableCell>
                                            {pkg.width} {units === "imperial" ? "in" : "cm"}
                                        </TableCell>
                                        <TableCell>
                                            {pkg.height} {units === "imperial" ? "in" : "cm"}
                                        </TableCell>
                                        <TableCell>
                                            {pkg.weight} {units === "imperial" ? "lb" : "kg"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
        </>
    );
}
