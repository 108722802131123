import { ColumnDef } from "@tanstack/react-table";
import { Address, Customer, Shipment } from "@shared/ShipmentTypes";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { editAddress, getAddress, removeAddress } from "@/api/addresses";
import { useState } from "react";
import { EditAddressDialog } from "./AddressDialog";
import { useAddressBookStore } from "@/core/AddressBookStore";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { AlertDialogTrigger } from "@radix-ui/react-alert-dialog";
import { toast } from "@/components/ui/use-toast";

export type AddressTableHeader = Customer & Address & { uid: string, address: Customer, addressBook: Customer[] };

export const columns: ColumnDef<AddressTableHeader>[] = [
    {
        accessorKey: "attentionName",
        header: "Attention Name"
    },
    {
        accessorKey: "companyName",
        header: "Company Name"
    },
    {
        accessorKey: "street",
        header: "Street Address"
    },
    {
        accessorKey: "city",
        header: "City"
    },
    {
        accessorKey: "stateCode",
        header: "Province"
    },
    {
        accessorKey: "postalCode",
        header: "Postal Code"
    },
    {
        id: "defaultAddress",
        header: "Defaults",
        cell: ({ row }) => {
            let out: string[] = []
            if (row.original.defaultShipper) {
                out.push("Shipper")
            }
            if (row.original.defaultReceiver) {
                out.push("Receiver")
            }
            if (row.original.defaultPickup) {
                out.push("Pickup")
            }

            if (out.length > 0) {
                return <div>{`Default ${out.join(" and ")} Address`}</div>
            }
        }
    },
    {
        id: "actions",
        // header: "Action"
        cell: ({ row }) => {
            const [open, setOpen] = useState(false);
            const [alertOpen, setAlertOpen] = useState(false);
            const [address, setAddress] = useState<Customer>({} as Customer);

            const removeAddress = async (e) => {
                e.stopPropagation();
                const index = parseInt(row.id);
                const address = useAddressBookStore.getState().getAddress(index);

                if (!address) {
                    toast({ variant: "destructive", title: "Unable to find address", description: "Please try again or contact support" })
                    return;
                }

                useAddressBookStore.getState().removeAddress(address);
                setAlertOpen(false);
                toast({ variant: "success", title: "Address successfully deleted" })
            }

            return (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={(e) => e.stopPropagation()}>
                                <span className="sr-only">Actions</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuItem
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const index = parseInt(row.id);
                                    const uid = row.original.uid;

                                    const address = useAddressBookStore.getState().getAddress(index);

                                    if (address) {
                                        setAddress(address);
                                        setOpen(!open);
                                    }
                                }}>
                                Edit Address
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={(e) => { e.stopPropagation; setAlertOpen(true) }}>
                                Remove Address
                            </DropdownMenuItem>

                            {row.original.defaultShipper
                                ?
                                <DropdownMenuItem
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const uid = row.original.uid;

                                        const { defaultShipper, ...rest } = row.original.address

                                        editAddress(row.original.address, rest, uid)
                                    }}>
                                    Remove Default Shipper Address
                                </DropdownMenuItem>
                                :
                                !row.original.defaultReceiver
                                    ?
                                    <DropdownMenuItem
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            const uid = row.original.uid;

                                            editAddress(row.original.address, { ...row.original.address, defaultShipper: true }, uid)

                                            const addresses = row.original.addressBook;

                                            for (const address of addresses) {
                                                if (address.defaultShipper) {
                                                    const { defaultShipper, ...rest } = address
                                                    editAddress(address, rest, uid)
                                                }
                                            }
                                        }}>
                                        Set As Default Shipper Address
                                    </DropdownMenuItem>
                                    : <></>}
                            {row.original.defaultReceiver
                                ?
                                <DropdownMenuItem
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const uid = row.original.uid;

                                        const { defaultReceiver, ...rest } = row.original.address

                                        editAddress(row.original.address, rest, uid)
                                    }}>
                                    Remove Default Receiver Address
                                </DropdownMenuItem>
                                :
                                !row.original.defaultShipper
                                    ?
                                    <DropdownMenuItem
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            const uid = row.original.uid;

                                            editAddress(row.original.address, { ...row.original.address, defaultReceiver: true }, uid)

                                            const addresses = row.original.addressBook;

                                            for (const address of addresses) {
                                                if (address.defaultReceiver) {
                                                    const { defaultReceiver, ...rest } = address
                                                    editAddress(address, rest, uid)
                                                }
                                            }
                                        }}>
                                        Set As Default Receiver Address
                                    </DropdownMenuItem>
                                    :
                                    <></>}
                            {row.original.defaultPickup
                                ?
                                <DropdownMenuItem
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const uid = row.original.uid;

                                        const { defaultPickup, ...rest } = row.original.address

                                        editAddress(row.original.address, rest, uid)
                                    }}>
                                    Remove Default Pickup Address
                                </DropdownMenuItem>
                                :
                                <DropdownMenuItem
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const uid = row.original.uid;

                                        editAddress(row.original.address, { ...row.original.address, defaultPickup: true }, uid)

                                        const addresses = row.original.addressBook;

                                        for (const address of addresses) {
                                            if (address.defaultPickup) {
                                                const { defaultPickup, ...rest } = address
                                                editAddress(address, rest, uid)
                                            }
                                        }
                                    }}>
                                    Set As Default Pickup Address
                                </DropdownMenuItem>}
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <EditAddressDialog open={open} setOpen={setOpen} defaultValues={address} />
                    <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction onClick={(e) => removeAddress(e)}>Continue</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </>
            );
        }
    }
];
