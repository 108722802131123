import { Dispatch, ReactNode } from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "@/components/ui/alert-dialog";

interface AlertDialogProps {
    title: string;
    description: string;
    children?: ReactNode;
    onConfirm: (...args: any[]) => void;
    resolved?: boolean; // If we need to set the state of a button to disabled (ex. void button on order details)
}

export const CustomAlertDialog = ({ title, description, children, onConfirm, resolved }: AlertDialogProps) => {
    return (
        <AlertDialog>
            <AlertDialogTrigger disabled={resolved}>
                {children}
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>{description}.</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={onConfirm}>Continue</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
