import { Button } from "@/components/ui/button";
import Input from "@/components/composite/Inputs/Input";
import { Label } from "@/components/ui/label";
import { Link } from "react-router-dom";
import Trucks from "@/assets/misc/Trucks.svg";
import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import AddressAutocomplete from "@/components/composite/Inputs/AddressAutocomplete";
import { memo, useEffect, useState } from "react";
import { FormProvider, get, useForm, useFormContext } from "react-hook-form";
import { GetQuoteSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useFormStore } from "@/core/ShipmentFormStore";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Address } from "@shared/ShipmentTypes";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Cylinder, Info, Mail, Package, Truck } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import FormTitle from "@/components/composite/Headers/FormTitle";
import Select from "@/components/composite/Inputs/Select";
import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { RatesResponse } from "@/types/ResponseTypes";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import getRates from "@/api/rates";
import useRatesStore from "@/core/RatesStore";
import { toast } from "@/components/ui/use-toast";
import RatesCard from "@/components/composite/Cards/RatesCard";
import { Skeleton } from "@/components/ui/skeleton";
import { Toaster } from "@/components/ui/toaster";
import { sendQuoteInfo } from "@/api/emails/sendQuoteInfo";

export default function GetQuotePage() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const methods = useForm<z.infer<typeof GetQuoteSchema>>({
        resolver: zodResolver(GetQuoteSchema),
        mode: "onChange",
        defaultValues: {
            shipmentDetails: {
                description: "test",
                units: "imperial",
                confirmation: true,
                printerType: "regular"
            }
        }
    });
    const {
        setValue,
        getValues,
        formState: { errors }
    } = methods;
    const [shipperAddress, setShipperAddress] = useState<Address>({
        street: String(getValues("shipperAddress.street") || ""),
        city: String(getValues("shipperAddress.city") || ""),
        postalCode: String(getValues("shipperAddress.postalCode") || ""),
        stateCode: String(getValues("shipperAddress.stateCode") || ""),
        countryCode: String(getValues("shipperAddress.countryCode") || "")
    });
    const [receiverAddress, setReceiverAddress] = useState<Address>({
        street: String(getValues("receiverAddress.street") || ""),
        city: String(getValues("receiverAddress.city") || ""),
        postalCode: String(getValues("receiverAddress.postalCode") || ""),
        stateCode: String(getValues("receiverAddress.stateCode") || ""),
        countryCode: String(getValues("receiverAddress.countryCode") || "")
    });
    useEffect(() => {
        shipperAddress.street !== "" && setValue("shipperAddress.street", shipperAddress.street);
        shipperAddress.city !== "" && setValue("shipperAddress.city", shipperAddress.city);
        shipperAddress.postalCode !== "" && setValue("shipperAddress.postalCode", shipperAddress.postalCode);
        shipperAddress.stateCode !== "" && setValue("shipperAddress.stateCode", shipperAddress.stateCode);
        shipperAddress.countryCode !== "" && setValue("shipperAddress.countryCode", shipperAddress.countryCode);

        receiverAddress.street !== "" && setValue("receiverAddress.street", receiverAddress.street);
        receiverAddress.city !== "" && setValue("receiverAddress.city", receiverAddress.city);
        receiverAddress.postalCode !== "" && setValue("receiverAddress.postalCode", receiverAddress.postalCode);
        receiverAddress.stateCode !== "" && setValue("receiverAddress.stateCode", receiverAddress.stateCode);
        receiverAddress.countryCode !== "" && setValue("receiverAddress.countryCode", receiverAddress.countryCode);
    }, [shipperAddress, receiverAddress]);

    const [shipmentType, setShipmentType] = useState("");

    const PackagingDetails = {
        Letter: LetterDetails,
        Package: PackageDetails,
        Tube: TubeDetails
    };
    const Details = PackagingDetails[shipmentType];

    const [rates, setRates] = useState<RatesResponse[]>([]);

    const onSubmit = async (data: z.infer<typeof GetQuoteSchema>) => {
        setIsLoading(true);
        console.log("Form submitted with data:", data);
        await getRates(data)
            .then(() => {
                if (useRatesStore.getState().rates.length > 0) {
                    setRates(useRatesStore.getState().rates);
                    setDialogOpen(true);
                } else {
                    toast({
                        title: "Error",
                        description: "No rates found.",
                        className: "bg-error-400"
                    });
                }
            })
            .catch((e) => {
                toast({
                    title: "Error",
                    description: "There was an error with the request. Please try again later.",
                    className: "bg-error-400"
                });
            })
            .finally(() => setIsLoading(false));

        sendQuoteInfo(data).catch((e) => { })
    };

    const shipperErrorMessage = get(errors, "shipperAddress")?.message;
    const receiverErrorMessage = get(errors, "receiverAddress")?.message;

    return (
        <>
            <Toaster />
            <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:max-h-[200px]" data-testid="getquote">
                <div className="relative flex">
                    <div className="absolute left-0 right-0 top-0 flex justify-between p-9">
                        <a href="https://www.snapship.co">
                            <img src={logo} className="h-10 place-self-center object-cover" />
                        </a>
                        <Link to="/login">
                            <Button variant="secondary">Login</Button>
                        </Link>
                    </div>
                    <div className="ml-9 mt-32 flex flex-col gap-2">
                        <div className="space-y-2">
                            <h1 className="text-3xl font-bold">Get a free quote!</h1>
                            <p className="text-muted-foreground text-balance">Fill in the form below to receive a free quote.</p>
                        </div>

                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
                                <div className="h-auto max-h-[calc(50vh-10rem)] space-y-6 overflow-y-auto pr-4 lg:max-h-[calc(50vh-5rem)] xl:max-h-[calc(50vh)]">
                                    <ShipmentType shipmentType={shipmentType} setShipmentType={setShipmentType} />
                                    <Card>
                                        <CardHeader>
                                            <CardTitle className="text-base font-medium">Origin and Destination</CardTitle>
                                        </CardHeader>
                                        <CardContent className="grid gap-4">
                                            <div className="grid gap-2">
                                                <Label htmlFor="shipperAddress">Shipper Address</Label>
                                                <AddressAutocomplete address={shipperAddress} setAddress={setShipperAddress}></AddressAutocomplete>
                                                {shipperErrorMessage !== undefined ? (
                                                    <p className="text-center text-xs text-red-500">{shipperErrorMessage}</p>
                                                ) : (
                                                    <p className="block text-xs text-red-500"> </p>
                                                )}
                                            </div>
                                            <div className="grid gap-2">
                                                <Label htmlFor="receiverAddress">Receiver Address</Label>
                                                <AddressAutocomplete address={receiverAddress} setAddress={setReceiverAddress}></AddressAutocomplete>
                                                {receiverErrorMessage !== undefined ? (
                                                    <p className="text-center text-xs text-red-500">{receiverErrorMessage}</p>
                                                ) : (
                                                    <p className="block text-xs text-red-500"> </p>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                    {Details && <Details isGetQuote={true} />}
                                    <Card>
                                        <CardContent className="gap-4 pt-4">
                                            <Label htmlFor="email" className="flex items-center gap-1 pb-2">Email
                                                <TooltipProvider delayDuration={50}>
                                                    <Tooltip>
                                                        <TooltipTrigger>
                                                            <Info className="h-4" />
                                                        </TooltipTrigger>
                                                        <TooltipContent className="w-72 text-left">
                                                            <p>Please verify that you are human by entering your email.</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </Label>
                                            <Input name="email" type="email" placeholder="Enter your email" />
                                        </CardContent>
                                    </Card>
                                </div>
                                <LoadingButton isLoading={isLoading} type="submit" className="w-full">
                                    Get Quote
                                </LoadingButton>
                            </form>
                        </FormProvider>
                        <Link to="/sign-up">
                            <Button variant="outline" className="w-full">
                                Create Your Free Account Now
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="hidden lg:block" style={{ backgroundColor: "#FCFCFC" }}>
                    <img src={Trucks} alt="" className="max-h-screen w-full" />
                </div>
            </div>
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogContent className="flex flex-col sm:max-w-[925px]">
                    <DialogHeader>
                        <DialogTitle>Rates</DialogTitle>
                        <DialogDescription></DialogDescription>
                    </DialogHeader>
                    <div className="grid h-[50vh] grid-cols-2 gap-4 overflow-y-auto pr-4">
                        {rates.length > 0 ? (
                            rates.sort((a, b) => a.cost - b.cost).map((rate, index: number) => <RatesCard Rates={rate} key={index} />)
                        ) : (
                            <Skeleton className="col-span-3 h-10 w-full" />
                        )}
                    </div>
                    <DialogFooter>
                        <Link to="/sign-up" className="m-auto">
                            <Button>
                                <Truck className="mr-2 h-4 w-4" />
                                Create Your Free Account Today
                            </Button>
                        </Link>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
}

const Units = [
    {
        name: "imperial",
        label: "Imperial (lb, in)"
    },
    {
        name: "metric",
        label: "Metric (kg, cm)"
    }
];

function ShipmentOptions() {
    return (
        <>
            <RadioButtons options={Units} name="units" defaultValue="imperial" />
        </>
    );
}

function ShipmentType({ shipmentType, setShipmentType }) {
    const {
        control,
        setValue,
        getValues,
        clearErrors,
        formState: { errors }
    } = useFormContext();

    const handleChange = (value: string) => {
        // console.log(value);
        setShipmentType(value);
        clearErrors("shipmentDetails.shipmentType");
        setValue("shipmentDetails.shipmentType", value);
        setValue("shipmentDetails.deliveryFormat", null);
        setValue("shipmentDetails.units", "imperial");
        setValue("shipmentDetails.packages", null);
    };

    useEffect(() => {
        handleChange(getValues("shipmentDetails.shipmentType"));
    }, []);

    const errorMessage = get(errors, "shipmentDetails.shipmentType")?.message;

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-base font-medium">Shipment Type</CardTitle>
                <CardDescription>Select which packaging type your shipment will be using.</CardDescription>
            </CardHeader>

            <CardContent>
                <ToggleGroup type="single" className="grid grid-cols-3 gap-4" onValueChange={handleChange} defaultValue={getValues("shipmentType")}>
                    <ToggleGroupItem value="Letter" className="flex items-center justify-center gap-3 p-2.5" name="shipmentType">
                        <Mail /> Envelope/Pak
                    </ToggleGroupItem>
                    <ToggleGroupItem value="Package" className="flex items-center justify-center gap-3 p-2.5" name="shipmentType">
                        <Package /> Package
                    </ToggleGroupItem>
                    <ToggleGroupItem value="Tube" className="flex items-center justify-center gap-3 p-2.5" name="shipmentType">
                        <Cylinder /> Triangular Tube
                    </ToggleGroupItem>
                </ToggleGroup>
                {errorMessage !== undefined ? <p className="text-center text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
            </CardContent>
        </Card>
    );
}

const TubeDetails = memo(function TubeDetails() {
    const formValues = useFormStore.getState().shipmentDetails;

    const { control, setValue, getValues } = useFormContext();

    useEffect(() => {
        setValue("shipmentDetails.deliveryFormat", "tube");
        setValue("shipmentDetails.packages.0.length", 0);
        setValue("shipmentDetails.packages.0.width", 0);
        setValue("shipmentDetails.packages.0.height", 0);
    }, []);

    return (
        <Card>
            <CardHeader className="flex-row justify-between">
                <CardTitle className="text-base font-medium">Tube Details</CardTitle>
                <ShipmentOptions />
            </CardHeader>
            <CardContent className="">
                <Input label="Weight" name="shipmentDetails.packages.0.weight" type="number" />
            </CardContent>
        </Card>
    );
});

const LetterDetails = memo(function LetterDetails() {
    const formValues = useFormStore.getState().shipmentDetails;

    const { getValues } = useFormContext();

    return (
        <Card>
            <CardHeader className="flex-row justify-between">
                <CardTitle className="text-base font-medium">Letter Details</CardTitle>
                <ShipmentOptions />
            </CardHeader>
            <CardContent className="grid gap-4">
                <LetterDeliveryFormat />
                <Input label="Weight" name="shipmentDetails.packages.0.weight" type="number" />
            </CardContent>
        </Card>
    );
});

const DeliveryFormat = [
    {
        name: "carrier",
        label: "Carrier Packaging"
    },
    {
        name: "package",
        label: "My Packaging"
    }
];

const LetterDeliveryFormat = () => {
    const {
        setValue,
        getValues,
        clearErrors,
        formState: { errors }
    } = useFormContext();

    const deliveryFormatValue = useFormStore.getState().shipmentDetails.deliveryFormat || getValues("shipmentDetails.deliveryFormat");

    const defaultValue = () => {
        if (deliveryFormatValue === "letter" || deliveryFormatValue === "pak") {
            return deliveryFormatValue;
        }
        return "letter";
    };

    const handleDeliveryFormatChange = (value: string) => {
        if (value !== "") {
            setValue("shipmentDetails.deliveryFormat", value);
            setValue("shipmentDetails.packages.0.length", 0);
            setValue("shipmentDetails.packages.0.width", 0);
            setValue("shipmentDetails.packages.0.height", 0);
            clearErrors("shipmentDetails.deliveryFormat");
            // console.log(getValues());
        }
    };

    const errorMessage = get(errors, "shipmentDetails.deliveryFormat")?.message;

    useEffect(() => {
        handleDeliveryFormatChange(defaultValue());
    }, []);

    return (
        <>
            <ToggleGroup type="single" className="grid grid-cols-2 gap-4" defaultValue={defaultValue()} onValueChange={handleDeliveryFormatChange}>
                <ToggleGroupItem value="letter" className="flex items-center justify-start gap-2 p-3" name="deliveryFormat">
                    Envelope
                    <TooltipProvider delayDuration={50}>
                        <Tooltip>
                            <TooltipTrigger>
                                <Info className="h-4" />
                            </TooltipTrigger>
                            <TooltipContent className="w-72 text-left">
                                <p>Only documents of no commercial value can be shipped with envelopes.</p>
                                <p>Less than 1 lbs or 0.4 kg</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </ToggleGroupItem>
                <ToggleGroupItem value="pak" className="flex items-center justify-start gap-2 p-3" name="deliveryFormat">
                    Pak
                    <TooltipProvider delayDuration={50}>
                        <Tooltip>
                            <TooltipTrigger>
                                <Info className="h-4" />
                            </TooltipTrigger>
                            <TooltipContent className="w-72 text-left">
                                <p>Please note that the weight limit for a courier PAK shipment is 3 lbs or 1.36 kg</p>
                                {/* <p>FedEx: No greater than 1 kg or 2.2 lbs</p>
                                    <p>UPS: No greater than 1.5 kg or 3 lbs</p>
                                    <p>Purolator: No greater than 2.2 kg or 5 lb</p> */}
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </ToggleGroupItem>
            </ToggleGroup>
            {errorMessage !== undefined ? <p className="text-center text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </>
    );
};

const PackageDetails = memo(function PackageDetails() {
    const { control, setValue, getValues } = useFormContext();

    const formValues = useFormStore.getState().shipmentDetails;

    const [count, setCount] = useState(0); // * zero index

    const deliveryFormatValue = useFormStore.getState().shipmentDetails.deliveryFormat || getValues("shipmentDetails.deliveryFormat");

    const defaultValue = () => {
        if (deliveryFormatValue === "carrier" || deliveryFormatValue === "package") {
            return deliveryFormatValue;
        }
        return "carrier";
    };

    return (
        <Card>
            <CardHeader className="flex-row justify-between">
                <FormTitle>Package Details</FormTitle>
                <ShipmentOptions />
            </CardHeader>
            <CardContent className="grid gap-6">
                <div>
                    <Select name="shipmentDetails.deliveryFormat" SelectItemList={DeliveryFormat} defaultValue={defaultValue()} />
                </div>
                <DimensionsInputList index={count} />
            </CardContent>
        </Card>
    );
});

const DimensionsInputList = ({ index }) => {
    const { control, setValue, getValues } = useFormContext();

    return (
        <>
            <h2 className="text-base">Package {index + 1}</h2>
            <div className="grid grid-cols-4 gap-6">
                <Input label={"Weight"} name={`shipmentDetails.packages.${index}.weight`} type="number" />
                <Input label={"Length"} name={`shipmentDetails.packages.${index}.length`} type="number" />
                <Input label={"Width"} name={`shipmentDetails.packages.${index}.width`} type="number" />
                <Input label={"Height"} name={`shipmentDetails.packages.${index}.height`} type="number" />
            </div>
        </>
    );
};

const sortByRates = (a: RatesResponse, b: RatesResponse) => {
    const cost1 = a.cost;
    const cost2 = b.cost;

    if (cost1 < cost2) {
        return -1;
    }

    if (cost1 > cost2) {
        return 1;
    }

    return 0;
};
